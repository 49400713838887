import moment from "moment";
import { partnerUploadDocument } from "../../../slices/PartnerMangement/PartnerDocumentUpload/thunk";
import { resetDocumentViewState } from "../../../slices/customer/documentview/reducer";
import { deleteDoc } from "../../../slices/customer/documentdelete/thunk";
import { documentUpdateJsonForPartner } from "../../UserManagement/Constant/JsonConstant";

export function extractedData(data) {
    return data.map(item => {
        const domainCharacteristic = item.partyCharacteristic?.find(pc => pc.name === 'domain');
        let domainValue = '-';
        if (domainCharacteristic) {
            switch (domainCharacteristic.value) {
                case 'operatorAdmin':
                    domainValue = 'EV';
                    break;
                case 'TelcoAdmin':
                    domainValue = 'Telco';
                    break;
                case 'genericAdmin':
                    domainValue = 'Generic';
                    break;
                default:
                    domainValue = domainCharacteristic.value;
                    break;
            }
        }
        return {
            endDateTime: moment.utc(item.existsDuring.endDateTime).format("DD MMM, YYYY"),
            startDateTime: moment.utc(item.existsDuring.startDateTime).format("DD MMM, YYYY"),
            organizationType: item.organizationType,
            tradingName: item.tradingName,
            operatorId: item.operatorId,
            status: item.status,
            domain: domainValue
        };
    });
}

export const documentUpdateJson = (logoFile,operatorId,editData) => {
    var formdata = new FormData();
    const roleName = localStorage.getItem("roleName")
    formdata.append("file", logoFile);
    formdata.append("ID", operatorId);
    if(roleName === "systemUser"){
        formdata.append("userType","operator")
    }
    formdata.append("documentName", `logo`);
    formdata.append("documentType", "logo");
    formdata.append("newDocumentName", `logo`)
    formdata.append("uploadedBy", `${editData?.tradingName}`);
    return formdata
}

export const documentJson = (logoFile,operatorId,editData) => {
    var formdata = new FormData();
    const roleName = localStorage.getItem("roleName")
    formdata.append("file", logoFile);
    formdata.append("ID", operatorId);
    if(roleName === "systemUser"){
        formdata.append("userType","operator")
    } 
    formdata.append("documentName", `logo`);
    formdata.append("documentType", "logo");
    formdata.append("uploadedBy", `logo`);
    return formdata
}

export const uploadDocApiCall = (logoFile,operatorId,editData,setLoading,setFileSelected,dispatch,navigate) => {
  let documentObj =  documentJson(logoFile,operatorId,editData)
  let partnerOrCustomer = "Operator";
  dispatch(partnerUploadDocument(documentObj, setLoading, operatorId, editData?.tradingName, partnerOrCustomer, setFileSelected,navigate));
};

export const uploadDocApiCallForPartner = (logoFile,PartnerId,editData,setLoading,setFileSelected,dispatch,navigate) => {
    let documentObj =  documentUpdateJsonForPartner(logoFile,PartnerId,editData)
    let partnerOrCustomer = "Partner";
    dispatch(partnerUploadDocument(documentObj, setLoading, PartnerId, editData, partnerOrCustomer, setFileSelected,navigate));
  };
  

export const handleRemoveLogo = (dispatch,setLoading,setLogoFile,setImageOn,setUpdate,fileInputRef,documentName,userId,isEdit) => {
    const payload = {
        "ID": userId,
        "documentName": documentName
    }
    if(isEdit){dispatch(deleteDoc(setLoading, payload, userId,"Operator"));}
    setLogoFile(null);
    setImageOn(true)
    setUpdate(true)
    dispatch(resetDocumentViewState());

    if (fileInputRef.current) {
        fileInputRef.current.value = '';
    }
};


export function camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    }).replace(/\s+/g, '');
  }