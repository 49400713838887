import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    OperatorSpecificConfiguration : [],
    loading : false,
    error : null,
};

const OperatorSpecificConfigurationSlice = createSlice({
    name:"OperatorSpecificConfiguration",
    initialState,
    reducers: {
        fetchOperatorSpecificConfigurationSuccess: (state,action) => {
            state.loading = false;
            state.OperatorSpecificConfiguration = action.payload;
        },
        fetchOperatorSpecificConfigurationFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    },
})

export const {
    fetchOperatorSpecificConfigurationSuccess,
    fetchOperatorSpecificConfigurationFailure
} = OperatorSpecificConfigurationSlice.actions

export default OperatorSpecificConfigurationSlice.reducer