export const SUCCESS_MESSAGE = 'SUCCESS_MESSAGE';
export const EDIT_SUCCESS_MESSAGE = 'EDIT_SUCCESS_MESSAGE'
export const DOCUMENT_SUCCESS_MESSAGE = 'DOCUMENT_SUCCESS_MESSAGE'
export const DELETE_SUCCESS_MESSAGE = 'DELETE_SUCCESS_MESSAGE'
export const PASSWORD_SUCCESS_MESSAGE='PASSWORD_SUCCESS_MESSAGE'
export const EMAIL_UPDATE_SUCCESS_MESSAGE= 'EMAIL_UPDATE_SUCCESS_MESSAGE'
export const CUSTOMER_NOT_FOUND_MESSAGE = 'CUSTOMER_NOT_FOUND_MESSAGE'

export const setSuccessMessage = (value) => ({
  type: SUCCESS_MESSAGE,
  payload: value,
});

export const setEditSuccessMessage = (value) => ({
  type: EDIT_SUCCESS_MESSAGE,
  payload: value,
});

export const setDocumentSuccessMessage = (value) => ({
  type: DOCUMENT_SUCCESS_MESSAGE,
  payload: value
});

export const setDeleteSuccessMessage = (value) => ({
  type: DELETE_SUCCESS_MESSAGE,
  payload: value
});

export const setPasswordSuccessMessage = (value) => ({
  type: PASSWORD_SUCCESS_MESSAGE,
  payload: value
});

export const setEmailUpdateSuccessMessage = (value) => {
  return {
      type: EMAIL_UPDATE_SUCCESS_MESSAGE,
      payload: value
  };
};

export const setCustomerNotFoundMessage = (value) => {
  return {
    type: CUSTOMER_NOT_FOUND_MESSAGE,
    payload: value
  }
}