export const addThreshold = (thresholds, setThresholds, activeButtonIndex, setActiveButtonIndex) => {
    setThresholds([...thresholds, { thresholdValue: '', percentage: '' }]);
    setActiveButtonIndex([...activeButtonIndex, 0]);
};

export const removeThreshold = (index, thresholds, setThresholds, activeButtonIndex, setActiveButtonIndex, setFieldValue, values) => {
    setThresholds(thresholds.filter((_, i) => i !== index));
    setActiveButtonIndex(activeButtonIndex.filter((_, i) => i !== index));
    if (values.thresholds) {
        const updatedThresholds = values.thresholds.filter((_, i) => i !== index);
        setFieldValue('thresholds', updatedThresholds);
    }
};

export const handleThresholdChange = (index, field, value, thresholds, setThresholds) => {
    const updatedThresholds = [...thresholds];
    updatedThresholds[index] = {
        ...updatedThresholds[index],
        [field]: value,
        [field === 'thresholdValue' ? 'percentage' : 'thresholdValue']: ''
    };
    setThresholds(updatedThresholds);
};

export const toggleButton = (index, value, activeButtonIndex, setActiveButtonIndex, thresholds, setThresholds) => {
    const newActiveButtonIndex = [...activeButtonIndex];
    newActiveButtonIndex[index] = value;
    setActiveButtonIndex(newActiveButtonIndex);

    const updatedThresholds = [...thresholds];
    updatedThresholds[index] = { thresholdValue: '', percentage: '' };
    setThresholds(updatedThresholds);
};
