import moment from "moment"
import { formatPhoneNumberIntl } from "react-phone-number-input"
export const customerJsonObj = (values, isEdit, subscriptionsLength, dialCode, roleName, searchableValues, nonSearchableValues, startsOn, endsOn, selectedOfferCode,quantity) => {
    let attributesArray = []

    let phoneNumber = formatPhoneNumberIntl(dialCode)
    let dialCodeWithPlus = phoneNumber.match(/^\+\d+(\s|$)/)[0];
    let restOfNumber = phoneNumber.slice(dialCodeWithPlus.length);
    let mobileNumberWithoutSpaces = restOfNumber.replace(/\s/g, '');
    let dialCodeWithoutSpaces = dialCodeWithPlus.replace(/\s/g, '');

    searchableValues.forEach(item => {
        let value = item.value;
        if (typeof value === 'object' && value !== null) {
            value = value.value;
        }
        if (item.value !== '') {
            attributesArray.push({
                "name": item.name,
                "value": value
            });
        }
    });

    nonSearchableValues.forEach(item => {
        let value = item.value;
        if (typeof value === 'object' && value !== null) {
            value = value.value;
        }
        if (item.value !== '') {
            attributesArray.push({
                "name": item.name,
                "value": value
            });
        }
    });

    let subscriptionsArray = []
    if ((roleName === "telcoAdmin" || roleName ===  "telcoOperationUser" || roleName==="telcoBusinessUser" )&& startsOn && selectedOfferCode) {
        const convertedStartDateTime = moment(startsOn).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
        const convertedEndDateTime = moment(endsOn).endOf('day').format();
        const appendedZ = moment(convertedEndDateTime).utc().set({ hour: 23, minute: 59, second: 59 }).format();
        subscriptionsArray.push({
            "customerId": values.accountNumber,
            "offerCode": selectedOfferCode,
            "validFrom": convertedStartDateTime,
            ...(endsOn && {"validTo": appendedZ}),
            "quantity": quantity
        })
    }

    const jsonObj = JSON.stringify({
        customerId: (roleName === "telcoAdmin" || roleName ===  "telcoOperationUser" || roleName==="telcoBusinessUser") ? values.accountNumber : values?.customerId,
        firstName: values?.firstName,
        lastName: values?.lastName,
        userName: values?.userName,
        email: values?.email,
        password: values?.password,
        mobile: parseInt(mobileNumberWithoutSpaces),
        addressLine1: values?.addressLine1,
        addressLine2: values?.addressLine2,
        country: values?.country?.value,
        state: values?.state?.value,
        city: values?.city?.value,
        zipCode: +values?.zipCode,
        dialCode: dialCodeWithoutSpaces,
        billingProfile: { "bdom": values.bdom.value },
        ...(attributesArray.length !== 0 && {
            attributeList: attributesArray,
        }),
        ...((roleName === "telcoAdmin" || roleName ===  "telcoOperationUser" || roleName==="telcoBusinessUser") && { subscriptions: subscriptionsArray }),
    })
    subscriptionsArray = []
    return jsonObj
}

export const subscriptionsJsonObj = (customerData, customerId, id) => {
    let subscriptionID = ""
    customerData.subscriptionList.forEach((value, index) => {
        customerData.subscriptionList[index].subscribedOffers.forEach((offer,offerIndex) => {
            if (id.original.id === offer.id) {
                subscriptionID = customerData.subscriptionList[index].subscriptionId
            }
        });
    })
    const currentTime = moment().format("YYYY-MM-DDTHH:mm:ss[Z]");
    const jsonObj = JSON.stringify({
        customerId: customerId,
        id: id.original.id,
        offerCode: id.original.offerCode,
        subscriptionId: subscriptionID,
        validTo: currentTime,
        ...(customerData?.subscriptionList[id?.index]?.deviceId && { deviceId: customerData?.subscriptionList[id?.index]?.deviceId })
    })
    return jsonObj
}

export const simulateJsonObj = (values, customerId, urpOffers, fetchSummaryData, randomString) => {
    const matchingrateplan = urpOffers?.filter(offer => offer?.usageRatePlan[0]?.uomName === values?.uom?.label)
    const subscripitionId = fetchSummaryData?.resources?.filter(resource => {
        return matchingrateplan?.some(offer => offer?.offerCode === resource?.offerCode)
    })[0]?.subscriptionId
    const jsonObj = JSON.stringify({
        eventType: values.eventType,
        Id: customerId,
        transactionId: randomString,
        eventStartTime: moment((values.eventStartTime)?._d).format('YYYY-MM-DDTHH:mm:ss[Z]'),
        subscriptionId: subscripitionId,
        eventEndTime: moment((values.eventEndTime)?._d).format('YYYY-MM-DDTHH:mm:ss[Z]'),
        attributeList: [{
            name: values.uom.value,
            value: values.usageValue
        }]
    })
    return jsonObj
}

export const documentDataFunction = (fetchDocumentData) => {
    let documentsOfCustomer = [];
    if (fetchDocumentData) {
        fetchDocumentData.forEach((value, index) => {
            documentsOfCustomer.push({
                "documentName": value.documentName,
                "fileName": value.fileName,
                "documentType": value.documentType,
                "uploadedtime": value.uploadedtime,
                "uploadedBy": value.uploadedBy,
                "status": value.status
            });
        });
    }
    return documentsOfCustomer;
}

export const searchCustomerJsonData = (data) => {
    const transformedObject = {
        Name: `${data?.firstName} ${data?.lastName}`,
        Email: data?.email,
        msisdn: data?.attributeList?.find(attr => attr.name.toLowerCase() === "msisdn")?.value?.toString(),
        imsi: data?.attributeList?.find(attr => attr.name.toLowerCase() === "imsi")?.value?.toString(),
        CustomerId: data?.customerId,
        Address: `${data?.addressLine1}, ${data?.addressLine2}`
    };

    return [transformedObject];
}