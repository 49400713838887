import React from 'react';
import { Card, CardBody, CardText } from 'reactstrap';

export default function CustomerSubscriptionCard({ fetchCustomerSummaryData }) {
    const addOnResources = fetchCustomerSummaryData?.resources?.filter(resource => resource.isAddOn);
    const nonAddOnResources = fetchCustomerSummaryData?.resources?.filter(resource => !resource.isAddOn);
    const uniqueBaseOffers = nonAddOnResources?.filter((offer, index, self) =>
        index === self?.findIndex((o) => o?.offerCode === offer?.offerCode)
    );
    const uniqueAddOnOffers = addOnResources?.filter((offer, index, self) =>
        index === self?.findIndex((o) => o?.offerCode === offer?.offerCode)
    );
    const filteredDataUniqueBaseOffers = uniqueBaseOffers?.filter(item => item.subscriptionId !== "");
    const uniqueAddOnOfferLength = uniqueAddOnOffers?.length || 0;
    const uniqueBaseOfferLength = filteredDataUniqueBaseOffers?.length || 0;
    function getValidItems(dataArray) {
        const currentDate = new Date();
        return dataArray?.filter(item => {
            if (item.validTo && !isNaN(new Date(item.validTo))) {
                return new Date(item.validTo) >= currentDate;
            }
            return false;
        });
    }
    const validBaseItems = getValidItems(filteredDataUniqueBaseOffers);
    const validAddOnItems = getValidItems(uniqueAddOnOffers);
    const validBaseItemsLength = validBaseItems?.length || 0;
    const validAddOnItemsLength = validAddOnItems?.length || 0;
    const totalSubscriptions = validBaseItemsLength + validAddOnItemsLength;

    return (
        <Card className="shadow-sm flex-fill">
            <CardBody className="d-flex flex-column justify-content-center align-items-center">
                <div className="row w-100 mb-4 mb-md-5">
                    <div className="col-12 d-flex flex-column justify-content-center align-items-center">
                        <div 
                            className="bg-light rounded-1 p-2 d-flex justify-content-center align-items-center" 
                            style={{ minWidth: '100px' }}
                        >
                            <h2 className="m-0">{totalSubscriptions}</h2>
                        </div>
                        <CardText 
                            tag="h5" 
                            className="text-center fw-medium mb-0 mt-3" 
                            style={{ whiteSpace: 'nowrap' }}
                        >
                            {totalSubscriptions <= 1 ? "Active Subscription" : "Active Subscriptions"}
                        </CardText>
                    </div>
                </div>
                <div className="row w-100">
                    <div className="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center mb-4 mb-md-0">
                        <div 
                            className="bg-light rounded-1 p-2 d-flex justify-content-center align-items-center" 
                            style={{ minWidth: '100px' }}
                        >
                            <h2 className="m-0">{uniqueBaseOfferLength}</h2>
                        </div>
                        <CardText 
                            tag="h5" 
                            className="text-center fw-medium mb-0 mt-3"
                        >
                            Base Offer
                        </CardText>
                    </div>
                    <div className="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center">
                        <div 
                            className="bg-light rounded-1 p-2 d-flex justify-content-center align-items-center" 
                            style={{ minWidth: '100px' }}
                        >
                            <h2 className="m-0">{uniqueAddOnOfferLength}</h2>
                        </div>
                        <CardText 
                            tag="h5" 
                            className="text-center fw-medium mb-0 mt-3"
                        >
                            Add On Offer
                        </CardText>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}
