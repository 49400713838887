import * as currencyLibrary from 'currency-library';

export const typevalue = {
    name: "isCurrency",
    value: "Resource",
    options: [
        { value: true, label: 'Monetary' },
        { value: false, label: 'Non-Monetary' },
    ]
};

export const uomOptionsData = (uomData) => {
    let uomArray = []
    uomArray = uomData.map(uom => ({
        value: uom.name,
        label: uom.name
    }));
    return uomArray
}

const allCurrencies = currencyLibrary.getAll();

const optionCurrencyCode = allCurrencies.map((currency) => ({
    value: currency.isoCode,
    label: currency.isoCode,
}));

export const resourceOptions = [
    typevalue,
    {
        name: "currencyCode",
        value: "Resource",
        options: optionCurrencyCode
    }
]
export { allCurrencies, optionCurrencyCode };

export const resOptionsAddition = (uomOptions, aggregate, resourceData) => {
    let options = resourceOptions.filter((item) => item.name !== 'uom');

    if (aggregate) {
        options = options.map((item) => {
            if (item.name === 'isCurrency') {
                return {
                    ...item,
                    options: item.options.filter((option) => option.value === false)
                };
            }
            return item;
        });

        const aggregateOptions = resourceData
            .filter((resource) => !resource.isCurrency && !resource.isAggregate)
            .map((resource) => ({
                value: resource.resourceId,
                label: resource.name
            }));

        if (aggregateOptions.length > 0) {
            options.push({
                name: "aggregateResource",
                value: "Resource",
                options: aggregateOptions
            });
        }
    }

    // Add 'uom' options if uomOptions are provided
    if (uomOptions.length) {
        options.push({
            name: "uom",
            value: "Resource",
            options: uomOptions
        });
    }

    return options;
};


