import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createView } from '../../slices/View/CreateView/thunk';
import { Button, FormGroup, Label, Input, Col, Card } from 'reactstrap';
import { CreatableSelectField } from './CreatableSelectField';
import { deleteView } from '../../slices/View/DeleteView/thunk';

export const useColumnSettings = (pageName, columns) => {
    const dispatch = useDispatch();
    const view = useSelector((state) => state.View.getView);
    const userId = localStorage.getItem("userName");
    const [selectedView, setSelectedView] = useState('');
    const [viewColumns, setViewColumns] = useState(columns.map((col) => col.accessorKey));
    const [settingsModalOpen, setSettingsModalOpen] = useState(false);
    const [visibleColumns, setVisibleColumns] = useState({});
    const [loading, setLoading] = useState(false);
    const [defaultView, setDefaultView] = useState(false);
    const [isCreatingView, setIsCreatingView] = useState(false);

    useEffect(() => {
        if (view?.pages?.length) {
            const currentPage = view.pages.find((page) => page.pageName === pageName);
            if (currentPage?.views?.length) {
                const defaultView = currentPage.views.find((view) => view.defaultView);
                const initialView = defaultView || currentPage.views[0];
                if (initialView) {
                    setSelectedView(initialView.viewName);
                    setViewColumns(initialView.viewColumns);
                    setDefaultView(initialView.defaultView);
                    setIsCreatingView(false);
                } else {
                    setSelectedView('');
                    setViewColumns(columns.map((col) => col.accessorKey));
                    setDefaultView(false);
                    setIsCreatingView(true);
                }
            }
        }
    }, [view, pageName]);

    const toggleSettingsModal = () => {
        setSettingsModalOpen((prevState) => !prevState);

        if (!settingsModalOpen) {
            setSelectedView('');
            setViewColumns(columns.map((col) => col.accessorKey || col.header));
            setDefaultView(false);
            setIsCreatingView(true);
        }
    };

    const handleColumnVisibilityChange = (column) => {
        setVisibleColumns((prev) => ({
            ...prev,
            [column]: !prev[column],
        }));
    };

    const handleSaveSettings = () => {
        const visibleColumnsKeys = columns
            .filter(column => visibleColumns[column.accessorKey || column.header])
            .map(column => column.accessorKey || column.header);
    
        const validPageNames = ['uom', 'resource', 'attribute', 'service', 'baseOffer', 'addOnOffer', 'customer'];
        const isValidPage = validPageNames.includes(pageName);
        if (isValidPage) {
            visibleColumnsKeys.push('checkbox');
        }
            const saveData = {
            pagename: pageName,
            viewName: selectedView,
            columns: visibleColumnsKeys.length ? visibleColumnsKeys : viewColumns, 
            defaultView: defaultView,
        };
    
        dispatch(createView(saveData, setLoading));
        setViewColumns(saveData.columns);
        toggleSettingsModal();
    };
    
    const handleViewChange = (selectedOption) => {
        const selectedViewName = selectedOption?.value || '';
        setSelectedView(selectedViewName);
    
        const selectedViewData = view?.pages
            ?.find((page) => page.pageName === pageName)
            ?.views?.find((v) => v.viewName === selectedViewName);
    
        if (!selectedViewData) {
            setIsCreatingView(true);
            setViewColumns(columns.map((col) => col.accessorKey));
            setDefaultView(false);
        } else {
            setViewColumns(selectedViewData.viewColumns);
            setDefaultView(selectedViewData.defaultView);
            setIsCreatingView(false);
        }
    };


    const handleDeleteView = (viewName) => {
        setSelectedView('');
        setViewColumns(columns.map((col) => col.accessorKey));
        setIsCreatingView(true);
        dispatch(deleteView(userId, setLoading, viewName));
    };

    const handleDefaultViewChange = () => {
        setDefaultView((prev) => !prev);
    };
    const SettingsSidebar = () => (
        <Col lg={2} className={`${settingsModalOpen ? '' : 'd-none'} rounded mb-4 `}>
            <Card className={` mb-4 h-100 w-100 p-4`}>
            <div >
                <FormGroup>
                    <Label for="viewSelect">Select or Create View</Label>
                    <CreatableSelectField
                        name="viewSelect"
                        options={view?.pages?.find((page) => page.pageName === pageName)?.views?.map((v) => ({
                            value: v.viewName,
                            label: (
                                <div className="d-flex justify-content-between align-items-center">
                                    {v.viewName}
                                    <Button
                                        className="btn-sm ms-2"
                                        color="danger"
                                        onClick={(e) => {
                                            e.stopPropagation()
                                            handleDeleteView(v.viewName)
                                        }}
                                    >
                                        <i className="ri-delete-bin-line"></i>
                                    </Button>
                                </div>
                            ),
                        })) || []}
                        isMulti={false}
                        value={selectedView ? { value: selectedView, label: selectedView } : ''}
                        handleChange={handleViewChange}
                        placeholder="Select View"
                    />
                </FormGroup>
                {isCreatingView && (
                    <FormGroup>
                        <Label>Columns Visibility</Label>
                        {columns.map((column) => (
                            column.accessorKey !== "checkbox" ? <div key={column.accessorKey || column.header} className="form-check form-switch">
                                <Input
                                    type="switch"
                                    id={column.accessorKey === "status" ? "Status" : column.accessorKey || column.header}
                                    checked={visibleColumns[column.accessorKey || column.header]}
                                    onChange={() => handleColumnVisibilityChange(column.accessorKey || column.header)}
                                    className="form-check-input"
                                />
                                <Label for={column.accessorKey || column.header} className="form-check-label">
                                    {column.header}
                                </Label>
                            </div> : null
                        ))}
                    </FormGroup>
                )}

                <FormGroup>
                    <Input
                        type="checkbox"
                        id="defaultViewCheckbox"
                        checked={defaultView}
                        onChange={handleDefaultViewChange}
                        className="me-2"
                    />
                    <Label for="defaultViewCheckbox" className="form-check-label">Set as Default View</Label>
                </FormGroup>

                <Button color="secondary" className="me-2" onClick={toggleSettingsModal}>Cancel</Button>
                <Button color="main-color" onClick={handleSaveSettings}>Save</Button>
            </div>
            </Card>
        </Col>
    );

    return {
        settingsModalOpen,
        visibleColumns,
        selectedView,
        viewColumns,
        toggleSettingsModal,
        handleColumnVisibilityChange,
        handleSaveSettings,
        handleViewChange,
        SettingsSidebar,
    };
};
