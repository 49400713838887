import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    sessions: [],
    loading: false,
    error: null,
}

const sessionsSlice = createSlice({
    name: "sessions",
    initialState,
    reducers: {
        fetchSessionSuccess: (state, action) => {
            state.loading = false,
                state.sessions = action.payload;
        },
        fetchSessionFailure: (state, action) => {
            state.loading = false,
                state.error = action.payload;
        },
    },
})

export const {
    fetchSessionSuccess,
    fetchSessionFailure,
} = sessionsSlice.actions;

export default sessionsSlice.reducer;