import { changePlan } from "../../../slices/customer/changeplan/thunk"

export const submitChangePlan = (customerIdPath,offerCode,subId,immediate,immediateMinus,nextBillCycle,setLoading,dispatch,setCondition) => {

    const jsonObj = {
        customerId: customerIdPath,
        offerCode: offerCode,
        subscriptionId: subId,
        immediate: immediate,
        immediateMinusUsed: immediateMinus,
        nextBillCycle: nextBillCycle,
    }
    
    dispatch(changePlan(jsonObj,customerIdPath,setLoading,setCondition))
}