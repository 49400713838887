import { Card, CardBody, CardHeader, Col, Form, Row } from "reactstrap";
import BreadCrumb from "../Common/BreadCrumb";
import { PAGE_TITLES } from "../Common/constants";
import { LabelText } from "../Utils/Input";
import formSchema from '../Utils/schema.json';
import { Formik } from "formik";
import { validationSchemaForTelcoSimulate } from "./constants/validationSchema";
import { RenderField } from "../Utils/renderFields";
import { RenderErrorMessage } from "../Utils/renderErrorMessages";
import CommonButton from "../Utils/CommonButton";
import { useLocation, useNavigate } from "react-router-dom";
import { customerIdData } from "./constants/constantFunctionsView";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Loader } from "../Utils/Loader";
import { initiateSessionApiCall, optionsForSubscriptionId, uomOptions } from "./constants/SessionFunctions";
import { fetchEventTypes } from "../../slices/sessions/eventType/thunk";
import { ToastContainer } from "react-toastify";
import { fetchDetailedBalance } from "../../slices/customer/detailedBalance/thunk";

export const TelcoSimulate = () => {
    const navigate = useNavigate()
    const customerId = customerIdData()
    const dispatch = useDispatch()
    const location = useLocation();
    const [loading, setLoading] = useState(false)
    const [eventTypeData, setEventTypeData] = useState()
    const [uomData, setUomData] = useState()
    const fullName = location.state.Name

    let initialValues = {
        subscriptionId: '',
        usageEventType: '',
        sessionId: '',
        sessionTime: null,
        requestedUnits: '',
        uom: ''
    }

    useEffect(() => {
        if (customerId) {
            dispatch(fetchDetailedBalance(customerId, setLoading, false));
        }
    }, [customerId])

    let detailedBalanceData = useSelector(
        (state) => state.DetailedBalance.detailedBalance
    );

    let eventType = useSelector((state) => state.EventType.eventType)

    const optionsOfSubscriptions = optionsForSubscriptionId(detailedBalanceData?.resources)

    const simulateOptions = [
        {
            name: "subscriptionId",
            value: "TelcoSimulate",
            options: optionsOfSubscriptions
        },
        eventTypeData,
        uomData
    ]
    return (
        <Formik initialValues={initialValues} validationSchema={validationSchemaForTelcoSimulate} onSubmit={(value) => {
            initiateSessionApiCall(value, dispatch, setLoading, navigate, customerId, fullName)
        }} >
            {({
                handleChange,
                handleBlur,
                handleSubmit,
                errors,
                touched,
                values,
                setFieldValue,
                setFieldTouched,
                setTouched,
                resetForm,
            }) => {
                useEffect(() => {
                    if (values?.subscriptionId?.value) {
                        dispatch(fetchEventTypes(values?.subscriptionId?.value, setLoading, setEventTypeData))
                    }
                    setFieldValue("usageEventType", "")
                }, [values?.subscriptionId?.value])
                useEffect(() => {
                    if (values?.usageEventType?.value) {
                        let UOMOptions = uomOptions(eventType, values?.usageEventType?.value)
                        const extraObject = {
                            name: "uom",
                            value: "TelcoSimulate",
                            options: UOMOptions
                        };
                        setUomData(extraObject)
                    }
                    setFieldValue("uom", "")
                }, [values?.usageEventType?.value])
                return (
                    <Form id="csms" className="needs-validation" onSubmit={handleSubmit}>
                        <div className="page-content">
                            <BreadCrumb
                                title={PAGE_TITLES.USAGE_SIMULATE}
                                pageTitle={PAGE_TITLES.CUSTOMER}
                            />
                            <ToastContainer position="top-center" />
                            {loading && <Loader />}
                            <Card>
                                <Row>
                                    <Col lg={12}>
                                        <CardBody>
                                            <Row className="gy-4 mb-5 pb-4">
                                                <Col xxl={3} md={6}>
                                                    <LabelText displayText={"Subscriber Name"} />
                                                    <p>{fullName}</p>
                                                </Col>
                                                <Col xxl={3} md={6}>
                                                    <LabelText displayText={"Account Number"} />
                                                    <p>{customerId}</p>
                                                </Col>
                                            </Row>
                                            {formSchema.map((section, sectionIndex) => (
                                                <Row className="gy-4 py-2" key={sectionIndex}>
                                                    {section.title === "TelcoSimulate" && section.sections.map((subSection, subSectionIndex) => (
                                                        subSection.fields && subSection.fields.filter(field => {
                                                            if (field.name === "usageEventType") {
                                                                field.disable = !values.subscriptionId;
                                                            }
                                                            return true
                                                        }).map((field, fieldIndex) => (
                                                            subSection.sectionTitle !== "Initiate Session" && <Col xxl={6} md={6} key={fieldIndex}>
                                                                <LabelText
                                                                    htmlFor={field.name}
                                                                    className={field.required ? "text-danger" : ""}
                                                                    displayText={field.label}
                                                                    important={field.required ? "*" : ""}
                                                                />
                                                                <RenderField field={field}
                                                                    handleChange={handleChange}
                                                                    handleBlur={handleBlur}
                                                                    values={values}
                                                                    touched={touched}
                                                                    errors={errors}
                                                                    setFieldTouched={setFieldTouched}
                                                                    setFieldValue={setFieldValue} options={simulateOptions} />
                                                                <RenderErrorMessage
                                                                    field={field}
                                                                    touched={touched}
                                                                    errors={errors} />
                                                            </Col>
                                                        ))
                                                    ))}
                                                </Row>
                                            ))}
                                        </CardBody>
                                    </Col>
                                </Row>
                            </Card>
                            {formSchema.map((section, sectionIndex) => (
                                section.title === "TelcoSimulate" && section.sections && section.sections.map((subSection, subSectionIndex) => (
                                    subSection?.sectionTitle && <Card key={subSectionIndex}>
                                        <CardHeader className="align-items-center d-flex">
                                            <h4 className="card-title mb-0 flex-grow-1">
                                                {subSection?.sectionTitle}
                                            </h4>
                                        </CardHeader>
                                        <CardBody>
                                            <Row key={sectionIndex}>
                                                <Col lg={12}>
                                                    <Row className='gy-4'>
                                                        {subSection.fields.filter(field => {
                                                            if (field.name === "sessionId") {
                                                                field.disable = !values.subscriptionId;
                                                            }
                                                            if (field.name === "sessionTime") {
                                                                field.disable = !values.subscriptionId;
                                                            }
                                                            if (field.name === "requestedUnits") {
                                                                field.disable = !values.subscriptionId;
                                                            }
                                                            if (field.name === "uom") {
                                                                field.disable = !values.subscriptionId;
                                                            }
                                                            return true
                                                        }).map((field, fieldIndex) => (
                                                            <Col
                                                                xxl={3}
                                                                md={3}
                                                                lg={3}
                                                                key={`${subSectionIndex}-one-${fieldIndex}`}
                                                            >
                                                                <LabelText
                                                                    htmlFor={field.name}
                                                                    className={field.required ? "text-danger" : ""}
                                                                    displayText={field.label}
                                                                    important={field.required ? "*" : ""}
                                                                />
                                                                <RenderField
                                                                    field={field}
                                                                    handleChange={handleChange}
                                                                    handleBlur={handleBlur}
                                                                    values={values}
                                                                    touched={touched}
                                                                    errors={errors}
                                                                    setFieldTouched={setFieldTouched}
                                                                    setFieldValue={setFieldValue} options={simulateOptions}
                                                                />
                                                                <RenderErrorMessage
                                                                    field={field}
                                                                    touched={touched}
                                                                    errors={errors}
                                                                />
                                                            </Col>
                                                        ))}
                                                    </Row>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                ))
                            )
                            )}
                            <Col lg={12} className="d-flex justify-content-end mt-3">
                                {/* <CommonButton outline={true} className={"cancel-button-background me-3"} color={"main-color"} type={"reset"} buttonAction={() => navigate('/dashboard')} buttonText={"Cancel"} /> */}
                                <CommonButton color={"main-color"} type={'submit'} buttonText={"Initiate Session"} />
                            </Col>
                        </div>
                    </Form>
                )
            }}
        </Formik>
    );
};
