import { MaterialReactTable } from "material-react-table";
import { invoiceListColumns } from "./constants/CustomerTableColumns";
import { MenuItem } from '@mui/material';
import { downloadDocApiCall, invoiceChangeFunctionForAll, viewDocApiCall } from "./constants/HandleChangeFunctions";
import { InvoiceRowOption } from "./InvoiceRowOptions";
import { CardBody, Row } from "reactstrap";

export default function InvoiceListTable({ setProfileData, invoiceListData, dispatch, setLoading, columns, toggleSettingsModal, viewColumns }) {
    const invoiceArray = []
    let partnerOrCustomer = ""
    const invoiceDataForAll = invoiceChangeFunctionForAll(invoiceListData, invoiceArray)
    return (
        <CardBody>
            <Row className="mb-3 mt-1 table-responsive">
                <MaterialReactTable
                    icons={{
                        DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                    }}
                    displayColumnDefOptions={{
                        'mrt-row-actions': {
                            header: '',
                            enableHiding: true,
                            size: 52,
                            grow: true
                        },
                    }}
                    defaultColumn={{
                        minSize: 10,
                        maxSize: 9001,
                        muiTableBodyCellProps: {
                            sx: {
                                fontFamily: "Inter,sans-serif"
                            }
                        },
                        muiTableHeadCellProps: {
                            sx: {
                                fontFamily: "Inter,sans-serif"
                            }
                        }
                    }}
                    renderRowActionMenuItems={({ row, closeMenu }) =>[
                        <MenuItem key={`${row.index}.1`} onClick={() => { partnerOrCustomer = "Invoice", viewDocApiCall(dispatch, setLoading, row.original.id, invoiceListData[row.index].invoice_number,setProfileData), closeMenu() }}><i className="ri-eye-line me-2"></i>View</MenuItem>,
                        <MenuItem key={`${row.index}.2`} onClick={() => { downloadDocApiCall(dispatch, setLoading, row.original.id, invoiceListData[row.index].invoice_number), closeMenu() }}><i className="ri-download-line me-2" /> Download</MenuItem>
                    ]}
                    renderTopToolbarCustomActions={()=>(
                        <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between w-100">
                                <div className="d-flex flex-column flex-md-row">
                                </div>
                                <div className="d-flex align-items-center">
                                    <div color="secondary" className="btn-icon me-2 cursor-img" onClick={toggleSettingsModal}>
                                        <i className="ri-settings-2-line fs-4"></i>
                                    </div>
                                </div>
                            </div>
                    )}
                    enableDensityToggle={false}
                    enableRowActions columns={columns.filter((column) => viewColumns?.includes(column.accessorKey || column.header))} data={invoiceDataForAll} />
            </Row>
        </CardBody>
    )
}