import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { Col, Container, Form, Label, Row } from "reactstrap";
import BreadCrumb from "../Common/BreadCrumb";
import { ToastContainer } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import CommonButton from "../Utils/CommonButton";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../Utils/Loader";
import { customerIdData } from "../Customers/constants/constantFunctionsView";
import { validationSchemaForUser } from "../UserManagement/Constant/valiadationshema";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import 'react-phone-number-input/style.css';
import { createUser } from "../../slices/UserManagement/CreateUser/thunk";
import { updateUser } from "../../slices/UserManagement/UpdateUser/thunk";
import { getUserByName } from "../../slices/UserManagement/GetUserByName/thunk";
import { LabelText } from "../Utils/Input";
import ProviderDetail from "./ProviderDetail";
import { validationSchemaForProfile } from "./validationSchemaForProfile";
import { fetchCustomer } from "../../slices/customer/fetchcustomer/thunk";
import { fetchCountries } from "../../slices/customer/countries/thunk";
import { countryOptionss } from "../OperatorManagement/Constant/Options";
import { fetchStates } from "../../slices/customer/states/thunk";
import { fetchCities } from "../../slices/customer/cities/thunk";
import { handleSubmitFunction } from "../LightWeightCustomer/Constant/formUtil";

export default function CreateProvider({ profilePermission, isEdit, isProfile, roleName, customerPermission }) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation();
    const changePassword = location?.state?.changePassword || false;
    const [changePasswordStatus, setChangePasswordStatus] = useState(false);
    const [loading, setLoading] = useState()
    const [editData, setEditData] = useState(false);
    const customerId = localStorage.getItem("customerId")
    const [dialCode, setDialCode] = useState()
    const [subscriptionId, setSubscriptionId] = useState()
    const [stateData, setStatedata] = useState();
    const [cityData, setCityData] = useState()
    const [selectedCountry, setSelectedCountry] = useState()
    const [stateOptions, setStateOptions] = useState()
    const [selectedState, setSelectedState] = useState()
    const [callState, setCallState] = useState(false)
    const [callCity, setCallCity] = useState(false)
    let optionState = []
    const profile = location.pathname.includes('profile-user')
    const userId = localStorage.getItem('userId')
    const [isEditButton, setIsEditButton] = useState(false);

    const initialValues = {
        firstname: "",
        lastname: "",
        userNames: "",
        userrole: "",
        phone: "",
        email: "",
        status: "",
        newPassword: "",
        confirmNewPassword: ""
    }
    const initialFormValues = {
        accountNumber: '',
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        addressLine1: '',
        addressLine2: '',
        country: '',
        state: '',
        city: '',
        zipCode: '',
        dialCode: '',
        subscriptions: [],
        attributeList: []
    }
    useEffect(() => {
        if (roleName === "Customer") {
            dispatch(fetchCustomer(setLoading, customerId, initialFormValues, customerPermission, setSubscriptionId, true, setDialCode));
            dispatch(fetchCountries(setLoading,setCallState))
        }
        else {
            dispatch(getUserByName(userId, setLoading, initialValues, setEditData))
        }

    }, [dispatch])
    useEffect(() => {
        if (roleName === "Customer" && callState) {
            dispatch(fetchStates(selectedCountry, setCallCity, "isOperator", setStatedata))
        }
    }, [dispatch, isEdit, selectedCountry]);

    useEffect(() => {
        if (roleName === "Customer" && callCity) {
            dispatch(fetchCities( selectedCountry, selectedState, "isOperator", setCityData))
        }
    }, [dispatch, selectedCountry, selectedState]);

    const user = useSelector((state) => state.GetAllUserByName.getUsersByName)
    const fetchCustomerData = useSelector(state => state.FetchCustomer.fetchCustomer)
    const countries = useSelector(state => state.Countries.countries)
    const countryOptions = countryOptionss(countries?.countries)
    const profileOptions = [
        {
            name: "country",
            value: "Profile",
            options: countryOptions
        }
    ]
    
    return (
        <React.Fragment>
            <div id='csms' >
                <Formik
                    validationSchema={validationSchemaForProfile()}
                    initialValues={roleName !== "Customer" ? initialValues : initialFormValues}
                >
                    {({ handleChange, handleBlur, handleSubmit, errors, touched, values, setFieldValue, setFieldTouched }) => (
                        <Form className='needs-validation' onSubmit={handleSubmit}>
                            <Container fluid>
                                <ToastContainer position='top-center' />
                                <Row>
                                    {loading && (<Loader />)}
                                    <Col lg={12}>
                                        <div >
                                            {profilePermission &&
                                                <ProviderDetail setDialCode={setDialCode} customerId={customerId} dialCode={dialCode} setIsEdit={setIsEditButton} isEdit={isEditButton} setSelectedState={setSelectedState} stateData={stateData} cityData={cityData} setSelectedCountry={setSelectedCountry} profileOptions={profileOptions} fetchCustomerData={fetchCustomerData} profilePermission={profilePermission} values={values} errors={errors} touched={touched} setFieldTouched={setFieldTouched} setFieldValue={setFieldValue} handleBlur={handleBlur} handleChange={handleChange} user={user} userId={userId} navigate={navigate} dispatch={dispatch} setLoading={setLoading} changePassword={changePassword} profile={profile} setChangePasswordStatus={setChangePasswordStatus} isProfile={isProfile} />
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Form>
                    )}
                </Formik>
            </div>
        </React.Fragment>
    )
}