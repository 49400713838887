import moment from "moment";
import { updateSession } from "../../../slices/sessions/updateSession/thunk";
import { terminateSession } from "../../../slices/sessions/terminateSession/thunk";
import { initiateSession } from "../../../slices/sessions/initiateSession/thunk";

export const goToIndex = (newIndex, animating, setActiveIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
};

export const grantData = (balanceData) => {
    return balanceData?.filter(item => !Object.keys(item).includes('currencyCode'));
}

export const sessionJsonAndApiCall = (updateRequestedUnit, updateUsedUnit, terminateUsedUnit, value, customerId, dispatch, setLoading, whichCall, setUpdateRequestedUnit, setUpdateUsedUnit, setTerminateUsedUnit) => {
    const jsonObj = JSON.stringify({
        "eventType": whichCall === "Update" ? "Updated" : "Ended",
        "timestamp": moment().format('YYYY-MM-DDTHH:mm:ss[Z]'),
        "sessionId": value?.sessionId,
        "subscriptionId": value?.subscriptionId,
        "seqno": value?.seqno[(value?.seqno).length - 1] + 1,
        ...(whichCall === "Update" && { "requestedUnit": parseFloat(updateRequestedUnit) }),
        "usedUnit": parseFloat(whichCall === "Update" ? updateUsedUnit : terminateUsedUnit),
        "uom": value?.uom,
        "usageEventType": value?.usageEventType,
        "triggerReason": ""
    })
    if (whichCall === "Update") {
        dispatch(updateSession(jsonObj, setLoading, customerId, value?.subscriptionId, setUpdateRequestedUnit))
    }
    else if (whichCall === "Terminate") {
        dispatch(terminateSession(jsonObj, setLoading, customerId, value?.subscriptionId))
    }

    setUpdateRequestedUnit([])
    setUpdateUsedUnit("")
    setTerminateUsedUnit("")

}

export const optionsForSubscriptionId = (commonID) => {
    const seen = new Set();
    const optionsForSelect = [];

    commonID?.forEach((common) => {
        const id = common?.subscriptionId;
        if (id && !seen.has(id)) {
            seen.add(id);
            optionsForSelect.push({ value: id, label: id });
        }
    });

    return optionsForSelect;
};

export const eventTypeOptions = (eventType) => {
    const eventOptions = eventType?.map((item) => {
        return {
            label: item.eventType,
            value: item.eventType,
        };
    }) || [];
    return eventOptions;
}

export const uomOptions = (uom, usageEventType) => {
    if (!uom || !usageEventType) return [];

    const matchingUOM = uom.find((item) => item.eventType === usageEventType);

    if (matchingUOM) {
        const UOMOptions = matchingUOM.uomName?.map((uom) => ({
            label: uom,
            value: uom,
        })) || [];

        return UOMOptions;
    }

    return [];
};


export const initiateSessionApiCall = (value,dispatch,setLoading,navigate,customerId,fullName) => {
    const jsonObj = JSON.stringify({
        "eventType": "Started",
        "timestamp": moment(value?.sessionTime).format('YYYY-MM-DDTHH:mm:ss[Z]'),
        "sessionId": value?.sessionId,
        "subscriptionId": value?.subscriptionId?.value,
        "seqno": 1,
        "requestedUnit": parseFloat(value?.requestedUnits),
        "uom": value?.uom?.value,
        "usageEventType": value?.usageEventType?.value,
    })
    dispatch(initiateSession(jsonObj, setLoading,navigate,customerId,fullName))
}

