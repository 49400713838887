import React from 'react';
import { Row, Col, Card, CardBody, CardHeader, FormGroup, FormFeedback, Button } from 'reactstrap';
import { LabelText } from '../Utils/Input';
import InputField from '../Utils/InputField';
import SelectField from '../Utils/SelectField';
import { handleChangeForCity, handleChangeForCountry, handleChangeForState } from "../Customers/constants/HandleChangeFunctions";
import { TypeOptions } from './Constants/PartnerUtils';
import { CreatableSelectField } from '../Utils/CreatableSelectField';
const BusinessProfileForm = ({
    handleChange,
    handleBlur,
    values,
    touched,
    setFileSelected,
    errors,
    setFieldValue,
    initialValues,
    options,
    setSelectedCountry,
    setFieldTouched,
    setSelectedState,
    setSelectedCity,
    setCities,
    optionState,
    optionCity,
    setLogoFile, setImageOn,
    fileInputRef,
    isCreate,
    handleRemoveLogo,
    logoFile,
    profile,
    imageOn,
    dispatch,
    setLoading,
    setUpdate,
    documentName,
    PartnerId,
    isEdit

}) => {
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setLogoFile(file);
            setImageOn(true);
            setFileSelected(true)
        } else {
            setLogoFile(null);
            setImageOn(false);
            setFileSelected(false)
        }
    };
    return (
        <Row>
            <Col lg={12}>
                <Card>
                    <CardBody className="card-body">
                        <div className="live-preview">
                            <Row className="gy-1">
                                <CardHeader >
                                    <h4 className="card-title flex-grow-1">Business Profile</h4>
                                </CardHeader>
                                <Col xxl={9}>
                                    <Row className=' mt-2 '>
                                        <Col xxl={8}>
                                            <div>
                                                <FormGroup >
                                                    <LabelText htmlFor={"businessName"} className={"text-danger"} displayText={"Business Name"} important={"*"} />
                                                    <InputField name={"businessName"} placeholder={"Enter your Business Name"} type={"text"} className={"form-control"} id={"businessName"} handleChange={handleChange} handleBlur={handleBlur} value={values.businessName}
                                                        invalid={touched.businessName && errors.businessName
                                                            ? true
                                                            : false} />
                                                    {touched.businessName &&
                                                        errors.businessName ? (
                                                        <FormFeedback type="invalid">
                                                            {errors.businessName}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col xxl={4} >
                                            <div>
                                                <LabelText htmlFor={"choices-single-default"} className={"text-danger"} displayText={"Type"} important={"*"} />
                                                <SelectField className={(errors.type && touched.type) || false ? 'error-input' : ''}
                                                    value={values.type}
                                                    placeholder={"Select Type"}
                                                    handleChange={(selectedVal) => setFieldValue("type", selectedVal)}
                                                    isClearable={true}
                                                    options={[
                                                        ...TypeOptions,
                                                    ]} />
                                                {touched.type && errors.type ? (
                                                    <p className="text-danger">
                                                        {errors.type}
                                                    </p>
                                                ) : null}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='mt-2'>
                                        <Col xxl={4} >
                                            <div >
                                                <FormGroup >
                                                    <LabelText htmlFor={"email"} className={"text-danger"} displayText={"Email Address"} important={"*"} />
                                                    <InputField name={"email"} placeholder={"Enter your Email"} type={"email"} className={"form-control"} id={"email"} handleChange={handleChange} handleBlur={handleBlur} value={values.email}
                                                        invalid={touched.email && errors.email
                                                            ? true
                                                            : false} />
                                                    {touched.email &&
                                                        errors.email ? (
                                                        <FormFeedback type="invalid">
                                                            {errors.email}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col xxl={4} >
                                            <div>
                                                <FormGroup >
                                                    <LabelText htmlFor={"mobile"} className={"text-danger"} displayText={"Phone Number"} important={"*"} />
                                                    <InputField name={"mobile"} placeholder={"Enter your Phone Number"} type={"number"} className={"form-control"} id={"mobile"} handleChange={handleChange} handleBlur={handleBlur} value={values.mobile}
                                                        invalid={touched.mobile && errors.mobile
                                                            ? true
                                                            : false} />
                                                    {touched.mobile &&
                                                        errors.mobile ? (
                                                        <FormFeedback type="invalid">
                                                            {errors.mobile}
                                                        </FormFeedback>
                                                    ) : null}
                                                </FormGroup>
                                            </div>
                                        </Col>
                                        <Col xxl={4} >
                                            <div>
                                                <FormGroup >
                                                    <LabelText htmlFor={"websiteLink"} className={"text-danger"} displayText={"Website Link"} />
                                                    <InputField name={"websiteLink"} placeholder={"Enter your Website Link"} type={"text"} className={"form-control"} id={"websiteLink"} handleChange={handleChange} handleBlur={handleBlur} value={values.websiteLink}
                                                    // invalid={touched.websiteLink && errors.websiteLink
                                                    //     ? true
                                                    //     : false}
                                                    />
                                                    {/* {touched.websiteLink &&
                                                        errors.websiteLink ? (
                                                        <FormFeedback type="invalid">
                                                            {errors.websiteLink}
                                                        </FormFeedback>
                                                    ) : null} */}
                                                </FormGroup>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xxl={3}>
                                    <FormGroup className='mt-4'>
                                        <LabelText htmlFor={"logo"} className={"text-danger"} displayText={"Logo"} />
                                        <div style={{ border: '1px dashed #ccc', padding: '38px', textAlign: 'center', maxWidth: '300px', margin: '0 auto', position: 'relative' }}>
                                            {logoFile ? (
                                                <>
                                                    <img
                                                        src={imageOn ? URL.createObjectURL(logoFile) : logoFile}
                                                        alt="Logo Preview"
                                                        style={{ maxWidth: '100%', height: 'auto' }}
                                                    />
                                                    <button
                                                        onClick={() => handleRemoveLogo(dispatch, setLoading, setLogoFile, setImageOn, setUpdate, fileInputRef, documentName, PartnerId, isEdit)}
                                                        style={{ position: 'absolute', top: '5px', right: '5px', background: 'none', border: 'none', cursor: 'pointer' }}
                                                    >
                                                        <span style={{ color: 'red', fontSize: '20px' }}>×</span>
                                                    </button>
                                                </>
                                            ) : (
                                                <label htmlFor="logoInput" style={{ cursor: 'pointer' }}>
                                                    <i className='ri-image-add-line align-middle me-1 fs-1 image-icon-color'></i>
                                                    <div><span className='text-danger'>*</span><span className='image-text-color'>File supported png., jpg., jpeg.</span></div>
                                                </label>
                                            )}
                                            <input
                                                type="file"
                                                id="logoInput"
                                                name="logo"
                                                accept=".jpg, .jpeg, .png"
                                                style={{ display: 'none' }}
                                                onChange={handleFileChange}
                                                ref={fileInputRef}
                                            />
                                        </div>
                                    </FormGroup>
                                </Col>

                                <Col xxl={6} className=' mb-3'>
                                    <FormGroup >
                                        <LabelText htmlFor={"Address"} className={"text-danger"} displayText={"Address"} important={"*"} />
                                        <InputField name={"Address"} placeholder={"Enter your Address"} type={"text"} className={"form-control"} id={"Address"} handleChange={handleChange} handleBlur={handleBlur} value={values.Address}
                                            invalid={touched.Address && errors.Address
                                                ? true
                                                : false} />
                                        {touched.Address &&
                                            errors.Address ? (
                                            <FormFeedback type="invalid">
                                                {errors.Address}
                                            </FormFeedback>
                                        ) : null}
                                    </FormGroup>
                                </Col>
                                <Col xxl={6} >
                                    <div>
                                        <LabelText htmlFor={"addressLine2"} className={"text-danger"} displayText={"Address 2"} />
                                        <InputField name={"addressLine2"} placeholder={"Enter your Address 2"} type={"text"} className={"form-control"} handleChange={handleChange} id={"addressLine2"} value={values.addressLine2} />
                                    </div>
                                </Col>
                                <Col xxl={3} >
                                    <div>
                                        <div>
                                            <LabelText htmlFor={"choices-single-default"} className={"text-danger"} displayText={"Country"} important={"*"} />
                                            <SelectField className={(errors.country && touched.country) || false ? 'error-input' : ''}
                                                value={initialValues.country ? options?.filter((country) => {
                                                    country.label === initialValues.country
                                                })
                                                    : values.country}
                                                placeholder={"Select your Country"}
                                                handleChange={handleChangeForCountry(setSelectedCountry, setFieldValue, setFieldTouched, setSelectedState, setSelectedCity, setCities)}
                                                isClearable={true}
                                                options={[
                                                    ...options,
                                                ]} />
                                            {touched.country && errors.country ? (
                                                <p className="text-danger">
                                                    {errors.country}
                                                </p>
                                            ) : null}
                                        </div>
                                    </div>
                                </Col>
                                <Col xxl={3} >
                                    <div>
                                        <FormGroup>
                                            <LabelText htmlFor={"choices-single-default"} className={"text-danger"} displayText={"State"} important={"*"} />
                                            <SelectField
                                                className={(errors.state && touched.state) ? 'error-input' : ''}
                                                value={initialValues.state ? optionState?.filter((state) => {
                                                    state.label === initialValues.state
                                                }) : values.state}
                                                placeholder="Select your State"
                                                handleChange={handleChangeForState(setSelectedState, setFieldValue)}
                                                options={[
                                                    ...optionState,
                                                ]}
                                                isClearable={true}
                                                isDisabled={!values.country}
                                            />
                                            {touched.state && errors.state ? (
                                                <p className="text-danger">
                                                    {errors.state}
                                                </p>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                </Col>
                                <Col xxl={3} >
                                    <div >
                                        <LabelText htmlFor={"choices-single-default"} className={"text-danger"} displayText={"City"} important={"*"} />
                                        <CreatableSelectField values={values.city} placeholder={"Select your city"} handleChange={(value) => {
                                            setFieldValue(`city`, value, false);
                                            setFieldTouched(`city`, false);
                                        }}
                                        className={(errors.city && touched.city) || false ? 'error-input' : ''}
                                            onKeyDown={(event) => {
                                                const key = event.key;
                                                const regex = /^[a-zA-Z0-9]$/;
                                                if (regex.test(key) || ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Enter'].includes(key)) {
                                                    return;
                                                }
                                                event.preventDefault();
                                            }}
                                            name={"city"} options={optionCity}
                                            fieldName={"city"}
                                            setFieldValue={setFieldValue}
                                            errors={errors.city}
                                            isClearable={true}
                                            isDisabled={!values.state}
                                            handleBlur={handleBlur}
                                            touched={touched.city}
                                        />
                                    </div>
                                </Col>
                                <Col xxl={3} >
                                    <div>
                                        <FormGroup >
                                            <LabelText htmlFor={"zipCode"} className={"text-danger"} displayText={"Zip Code"} important={"*"} />
                                            <InputField name={"zipCode"} placeholder={"Enter your Zip Code"} type={"number"} className={"form-control"} id={"zipCode"} handleChange={handleChange} handleBlur={handleBlur} value={values.zipCode}
                                                invalid={touched.zipCode && errors.zipCode
                                                    ? true
                                                    : false} />
                                            {touched.zipCode &&
                                                errors.zipCode ? (
                                                <FormFeedback type="invalid">
                                                    {errors.zipCode}
                                                </FormFeedback>
                                            ) : null}
                                        </FormGroup>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
};
export default BusinessProfileForm;
