import { toast } from "react-toastify";
import { VIEW_API } from "../../constants/constants";
import {  fetchCreateViewFailure, fetchCreateViewSucess } from "./reducer";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { getView } from "../thunk";

export const createView = (jsonObj, setLoading) => (dispatch) => {
    setLoading(true)
    determineBaseUrlAndPerformApiCall(`${VIEW_API}`, 'POST', jsonObj).then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchCreateViewSucess(response))
            dispatch(getView(setLoading))
            toast.success("View Created Successfully")
        }
        if (response.error) {
            dispatch(fetchCreateViewFailure(response.error.message))
            setLoading(false)
            toast.error(`${response.error.message}`)
        }
    }).catch((errorResp) => { })
}