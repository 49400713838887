import { fetchCreateCustomerSuccess, fetchCreateCustomerFailure } from "./reducer"
import { toast } from "react-toastify"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { REGISTER_CUSTOMER_API } from "../../constants/constants";
import { setSuccessMessage } from "../../toastMessage/action";
import { uploadDocument } from "../documentupload/thunk";


export const createCustomer = (jsonObj, setLoading, navigate,profilePicture) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${REGISTER_CUSTOMER_API}`, 'POST', jsonObj).then((response) => {
        if (!response.error) {
            const roleName=localStorage.getItem("roleName");
            setLoading(false)
            dispatch(fetchCreateCustomerSuccess(response))
            const formData = new FormData()
            formData.append("ID", response?.data?.customerId)
            if(roleName === "telcoAdmin"){
                formData.append("userType","customer")
            }
            formData.append("file", profilePicture);
            formData.append("documentName", "logo");
            formData.append("documentType", "logo");
            formData.append('uploadedBy', response?.data?.userName)
            if (profilePicture) {
                dispatch(uploadDocument(formData, setLoading, response?.data?.customerId, "Customer", navigate))
            }else{
                navigate('/customer')
                dispatch(setSuccessMessage(true))
            }
            const storedResponses = JSON.parse(localStorage.getItem('searchResponses')) || [];
            const updatedResponses = storedResponses.filter(storedResponse => {
                return storedResponse.customerId !== response.customerId;
            });
            updatedResponses.push(response);

            if (updatedResponses.length > 5) {
                updatedResponses.shift();
            }
            localStorage.setItem('searchResponses', JSON.stringify(updatedResponses));
        }
        if (response.error) {
            setLoading(false)
            toast.error(
                `${response.error.message}`
            )
            dispatch(fetchCreateCustomerFailure(response.error.message))
        }
    }).catch((errorResp) => {
    })
}