import { fetchSearchCustomerFailure, fetchSearchCustomerSuccess } from "./reducer"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { SEARCH_CUSTOMER_API } from "../../constants/constants";
import { toast } from "react-toastify";
import { getCustomerData } from "../../../Components/Customers/constants/ListingCustomerFunctions";
import { setCustomerNotFoundMessage } from "../../toastMessage/action";

export const getSearchedCustomer = (setLoading, msisdn, imsi, accountNumber, setGetTable, setErrorMessage, setErrorIcon, searchFields, isAI, stationPermission, reservationPermission, customerPermission, setRenderSimulate,setSearchExpanding) => async (dispatch) => {
    try {
        setLoading(true)
        const queryParams = [];
        if (isAI) {
            if (searchFields[0]?.value) {
                queryParams.push(`identifiers=${searchFields[0].value}`);
            }
        } else {
            searchFields.forEach((field, index) => {
                if (field?.value) {
                    queryParams.push(`identifiers=${field.value}`);
                }
            });
            if (accountNumber) {
                queryParams.push(`internalid=${accountNumber}`);
            }
        }
        const queryString = queryParams.join('&');
        const response = await determineBaseUrlAndPerformApiCall(`${SEARCH_CUSTOMER_API}?${queryString}`, 'GET').then((response) => {
            if (!response.error) {
                setLoading(false)
                setGetTable(true)
                let simulateOrPreview = "Preview"
                let id = { original: { CustomerId: response?.customerId } }
                if (!isAI) {
                    getCustomerData(id, simulateOrPreview, setLoading, stationPermission, reservationPermission, customerPermission, setRenderSimulate, dispatch)
                }
                dispatch(fetchSearchCustomerSuccess(response));
                setSearchExpanding(true);
                const storedResponses = JSON.parse(localStorage.getItem('searchResponses')) || [];
                const updatedResponses = storedResponses.filter(storedResponse => {
                    return storedResponse.customerId!== response.customerId;
                });
                updatedResponses.push(response);
                if (updatedResponses.length > 5) {
                    updatedResponses.shift(); 
                }
                localStorage.setItem('searchResponses', JSON.stringify(updatedResponses));
            }
            if (response.error) {
                dispatch(setCustomerNotFoundMessage(true))
                setErrorMessage('No Customers Found')
                setErrorIcon('ri-user-unfollow-fill')
                setLoading(false)
                setSearchExpanding(false);
                setGetTable(false)
            }
        })
    } catch (error) {
        dispatch(fetchSearchCustomerFailure(error.message));
    }
};

