export const goToIndex = (newIndex, animating, setActiveIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
};

export const next = (resources, activeIndex, setActiveIndex, animating) => {
    const nextIndex = activeIndex === resources.length - 1 ? 0 : activeIndex + 1;
    goToIndex(nextIndex, animating, setActiveIndex);
};

export const previous = (resources, activeIndex, setActiveIndex, animating) => {
    const prevIndex = activeIndex === 0 ? resources.length - 1 : activeIndex - 1;
    goToIndex(prevIndex, animating, setActiveIndex);
};