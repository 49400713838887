import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor"
import { ORDERS_LIST_API } from "../../constants/constants"
import { fetchCustomer } from "../../customer/fetchcustomer/thunk"
import { initialFormValues } from "../../../Components/Customers/constants/constantValues"
import { fetchGetOrderByCustomerIdFailure, fetchGetOrderByCustomerIdSuccess } from "./reducer"

export const getOrderByCustomerId = (orderId, setLoading) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${ORDERS_LIST_API}?relatedParty.partyOrPartyRole.id=${orderId}`, 'GET').then((response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchGetOrderByCustomerIdSuccess(response))
        }
        if (response.error) {
            setLoading(false)
            dispatch(fetchGetOrderByCustomerIdFailure(response.error.message))
        }
    }).catch((error) => { 
        setLoading(false);
    })
}