import moment from "moment";
import { Card, CardBody, CardHeader, Col, Row, Accordion, AccordionItem, AccordionHeader, AccordionBody, } from "reactstrap";
import { useEffect, useState } from "react";
import { LabelText } from "../Utils/Input";
import { useSelector } from "react-redux";

export const DetailedTaskView = ({ taskDetails, configurations }) => {
    const [openAccordion, setOpenAccordion] = useState("");

    const toggleAccordion = (open, setOpen, taskEnabledValue, ifbrmTaskEnabledValue) => {
    const toggle = (id) => {
        if (!taskEnabledValue && (id === '1' || id === '2')) {
            return;
        }
        if (!ifbrmTaskEnabledValue && (id === '3' || id === '4')) {
            return;
        }
        if (open === id) {
            setOpen(); 
        } else {
            setOpen(id); 
        }
    };

    return toggle;
};


    const formatTaskType = (taskType) => {
        return taskType
            .toLowerCase()
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };
    let taskEnabledValue, brmTaskEnabledValue;
    const configData = useSelector((state) => state.OperatorSpecificConfig.OperatorSpecificConfiguration)

    configData?.configurations.forEach(config => {
        if (config.name === "TaskManagement") {
            config.value.forEach(item => {
                if (item.key === "TaskEnabled") {
                    taskEnabledValue = item.value[0] === "True" ? true :false; 
                } else if (item.key === "BRMTaskEnabled") {
                    brmTaskEnabledValue = item.value[0] === "True" ? true :false; 
                }
            });
        }
    });

    useEffect(() => {
        if (brmTaskEnabledValue) {
            setOpenAccordion(false);
        }
    }, [brmTaskEnabledValue]);

    const toggle = toggleAccordion(openAccordion, setOpenAccordion, taskEnabledValue, brmTaskEnabledValue);

    return (
        <div >
            <CardHeader>
                <h4 className="card-title mb-0 flex-grow-1 ms-2">View Task</h4>
            </CardHeader>
            <CardBody>
                <Row className="gy-4 mb-4 ms-0 d-flex justify-content-between align-items-center">
                    <Col lg={4}>
                        <div className="fw-semibold mb-2">Task Type</div>
                        <div>{formatTaskType(taskDetails?.taskType)}</div>
                    </Col>

                    <Col lg={4} className="d-flex-row justify-content-end ">
                        <div className="fw-semibold mb-2">Task Start Time</div>
                        <div>
                            {moment(taskDetails?.taskTime).format("DD MMM YYYY, HH:mm:ss")}
                        </div>
                    </Col>

                    <Col lg={4} className="d-flex-row justify-content-end ">
                        <div className="fw-semibold mb-2">Task End Time</div>
                        <div>
                            {moment(taskDetails?.endTime).format("DD MMM YYYY, HH:mm:ss")}
                        </div>
                    </Col>
                </Row>

                <Row className=" ms-1">
                    <Col lg={6}>
                        <h5 >Internal</h5>
                        <Accordion className={`ms-n3 ${!taskEnabledValue ? 'global-state-true top-border-radius':''}`} open={openAccordion} toggle={toggle}>
                            <AccordionItem>
                                <div className="fw-semibold ms-0 mt-3 mb-1">
                                    <div>
                                        <Row className="d-flex justify-content-between align-items-end">
                                            <Col>
                                                <div className="fw-bold mb-2 mt-0 ms-3">Internal Status</div>
                                                <div className=" ms-3">
                                                    <span
                                                        className={`${!taskEnabledValue ? "text-muted" :taskDetails?.internalStatus === "FAILED"
                                                            ? "task-fail-color"
                                                            : taskDetails?.internalStatus === "SUCCESS"
                                                                ? "task-success-color"
                                                                : taskDetails?.internalStatus === "PENDING"
                                                                    ? "task-pending-color"
                                                                    : ""
                                                            }`}
                                                    >
                                                        {!taskEnabledValue ? "Not Enabled" :taskDetails?.internalStatus}
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr className="w-100" />
                                </div>
                                <AccordionHeader targetId="1" className="mb-0 mt-n3 offer-name ">
                                    Request Sent
                                </AccordionHeader>
                                <AccordionBody accordionId="1">
                                    {taskEnabledValue ? <pre className="task-color">
                                        {JSON.stringify(taskDetails?.requestSent, null, 2)}
                                    </pre> : <LabelText displayText={"The configuration is not supported"} />}
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="2" className="mb-0">
                                    Response Received
                                </AccordionHeader>
                                <AccordionBody accordionId="2" >
                                    {taskEnabledValue ?
                                        <pre className="task-color">
                                            {JSON.stringify(taskDetails?.responseReceived, null, 2)}
                                        </pre> : <LabelText displayText={"The configuration is not supported"} />}
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>
                    </Col>
                    <Col lg={6}>
                        <h5 className="me-2">External</h5>
                        <Accordion className={`ms-n3 ${!brmTaskEnabledValue ? 'global-state-true top-border-radius':''}`} open={openAccordion} toggle={toggle}>
                            <AccordionItem>
                                <div className="fw-semibold ms-0 mt-3 mb-1">
                                    <div>
                                        <Row className="d-flex justify-content-between align-items-end">
                                            <Col>
                                                <div className="fw-bold mb-2 mt-0 ms-3">Provisioning Status </div>
                                                <div className=" ms-3">
                                                    <span
                                                        className={`${!brmTaskEnabledValue ? "text-muted" : taskDetails?.provisioningStatus === "FAILED"
                                                            ? "task-fail-color"
                                                            : taskDetails?.provisioningStatus === "SUCCESS"
                                                                ? "task-success-color"
                                                                : taskDetails?.provisioningStatus === "PENDING"
                                                                    ? "task-pending-color"
                                                                    : ""
                                                            }`}
                                                    >
                                                        {!brmTaskEnabledValue ? "Not Enabled" :taskDetails?.provisioningStatus}
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    <hr className="w-100" />
                                </div>
                                <AccordionHeader targetId="3" className="mb-0 mt-n3">
                                    Request Sent
                                </AccordionHeader>
                                <AccordionBody accordionId="3">
                                    {brmTaskEnabledValue ? <pre className="task-color">
                                        {JSON.stringify(taskDetails?.externalTracker?.requestSent, null, 2)}
                                    </pre> : <LabelText displayText={"The configuration is not supported"}/>}
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId="4" className="mb-0">
                                    Response Received
                                </AccordionHeader>
                                <AccordionBody accordionId="4">
                                    {brmTaskEnabledValue ? <pre className="task-color">
                                        {JSON.stringify(taskDetails?.externalTracker?.responseReceived, null, 2)}
                                    </pre> : <LabelText displayText={"The configuration is not supported"}/>}
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>
                    </Col>
                </Row>
            </CardBody>
        </div>
    );
};
