import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import { MaterialReactTable } from "material-react-table";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import BreadCrumb from "../Common/BreadCrumb";
import { Loader } from "../Utils/Loader";
import CommonModal from "../Utils/CommonModal";
import { PolicyRowOptions } from "./PolicyRowOptions";
import { PolicyCustomButtons } from "./PolicyCustomButton";
import { PoliciesColoumn } from "./constants/PolicyColoumn";
import { getAllPolicies } from "../../slices/Policy/thunk";
import { deletePolicies } from "../../slices/Policy/DeletePolicy/thunk";
import { setEditSuccessMessage, setSuccessMessage } from "../../slices/toastMessage/action";
import { getView } from "../../slices/View/thunk";
import { useColumnSettings } from "../Utils/useColumnSettings";
import { MenuItem } from "@mui/material";

export default function PolicyListing() {
    const [loading, setLoading] = useState()
    const [messageForPopUp, setMessageForPopUp] = useState('')
    const [modal, setModal] = useState(false);
    const [policyName, setPolicyName] = useState()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const columns = PoliciesColoumn()
    const toggleModal = (id) => {
        setPolicyName(id)
        setModal(!modal);
        document.body.style.overflow = 'auto'
    };
    useEffect(() => {
        dispatch(getAllPolicies(setLoading));
        dispatch(getView(setLoading))
    }, [dispatch]);
    const Policies = useSelector((state) => state?.GetAllPolicies?.getAllPolicies);
    const showSuccessMessage = useSelector((state) => state.SuccessToast.showSuccessMessage)
    const showEditSuccessMessage = useSelector((state) => state.SuccessToast.showEditSuccessMessage);

    const {
        settingsModalOpen,
        viewColumns,
        toggleSettingsModal,
        SettingsSidebar
    } = useColumnSettings('policy', columns);
    useEffect(() => {
        if (showSuccessMessage) {
            toast.success(`Policy Created Successfully`);
            dispatch(setSuccessMessage(false))
        }
        if (showEditSuccessMessage) {
            toast.success(`Policy Updated Successfully`);
            dispatch(setEditSuccessMessage(false));
        }
    }, [showSuccessMessage, showEditSuccessMessage, dispatch])
    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                <Container fluid>
                    <BreadCrumb title="Policy List" pageTitle="Policy" />
                    <ToastContainer position='top-center' />
                    <Row>
                        <Col lg={settingsModalOpen ? 10 : 12}>
                            <Card>
                                <CardBody>
                                    <Row className="mb-3 mt-1 table-responsive">
                                        {loading && (<Loader />)}
                                        {Policies ?
                                            <MaterialReactTable
                                                icons={{ DragHandleIcon: () => <i className="ri-drag-move-fill" />, }}
                                                renderTopToolbarCustomActions={({ }) => (
                                                    <PolicyCustomButtons toggleSettingsModal={toggleSettingsModal} Policies={Policies} navigate={navigate} />
                                                )}
                                                displayColumnDefOptions={{
                                                    'mrt-row-actions': {
                                                        header: '',
                                                        enableHiding: true,
                                                        size: 17,
                                                        grow: true
                                                    },
                                                }}
                                                defaultColumn={{
                                                    minSize: 10,
                                                    maxSize: 9001,
                                                    size: 260,
                                                    muiTableBodyCellProps: {
                                                        sx: {
                                                            fontFamily: "Inter,sans-serif"
                                                        }
                                                    },
                                                    muiTableHeadCellProps: {
                                                        sx: {
                                                            fontFamily: "Inter,sans-serif"
                                                        }
                                                    }
                                                }}
                                                layoutMode='grid'
                                                columns={columns.filter((column) => viewColumns?.includes(column.accessorKey || column.header))}
                                                data={Policies}
                                                enableRowActions
                                                enableColumnOrdering
                                                enableColumnDragging
                                                enableDensityToggle={false}
                                                renderRowActionMenuItems={({ closeMenu, row }) => [
                                                    <MenuItem className='row-options' data-testid="update" key="update" onClick={() => { navigate(`/edit-policies/${row.original.policyLabel}`) }} >
                                                        <i className='bx bxs-edit me-2' /> Edit </MenuItem>,
                                                    <MenuItem className='row-options' key="delete" onClick={() => { setMessageForPopUp(`Are you sure you want to Delete this Policy "${row.original.policyLabel}"?`), toggleModal(row), closeMenu() }}>
                                                        <i className="ri ri-delete-bin-5-line me-2" />{" "}
                                                        Delete
                                                    </MenuItem>,
                                                ]}
                                            /> : null}
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                        {SettingsSidebar()}
                    </Row>
                </Container>
            </div>
            <div>
                <CommonModal open={modal} toggle={toggleModal} buttonText={"Yes, Delete It"} messageForPopUp={messageForPopUp} modalAction={() => {
                    dispatch(deletePolicies(policyName.original?.policyLabel, setLoading))
                    toggleModal()
                }} />
            </div>
        </React.Fragment>
    )
}