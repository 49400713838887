import { fetchUpdateCustomerSuccess, fetchUpdateCustomerFailure } from "./reducer";
import { toast } from "react-toastify"
import { UPDATE_CUSTOMER_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { setEditSuccessMessage, setEmailUpdateSuccessMessage } from "../../toastMessage/action";
import { fetchCustomer } from "../fetchcustomer/thunk";
import { documentUpdateFile } from "../documentupdatefile/thunk";
import { uploadDocApiCall } from "../../../Components/Customers/constants/constantFunctions";

export const updateCustomer = (jsonObj, customerId, setLoading, navigate,fileSelected,setFileSelected,update,setUpdate,profile,profilePicture,isProfile,editData) => async (dispatch) => {
    setLoading(true)
    const roleName = localStorage.getItem("roleName")
    await determineBaseUrlAndPerformApiCall(`${UPDATE_CUSTOMER_API}/${customerId}`, 'PUT', jsonObj).then((response) => {
        if (roleName !== 'Customer') {
            if (!response.error) {
                setLoading(false)
                dispatch(fetchUpdateCustomerSuccess(response))
                if (profilePicture) {
                    if (profile && update){
                        setUpdate(false)
                    }
                    if (fileSelected && profilePicture !== null) {
                        if (profile && update) {
                            const jsonObj = new FormData()
                            formdata.append("file", profilePicture);
                            formdata.append("ID", response?.data?.customerId);
                            if(roleName === "telcoAdmin"){
                                formdata.append("userType","customer")
                            }
                            formdata.append("userType","customer")
                            formdata.append("documentName", "logo");
                            formdata.append("documentType", "logo");
                            formdata.append("newDocumentName", "logo")
                            formdata.append("uploadedBy", `${response?.data?.userName}`);
                            let partnerOrCustomer = "Customer";
                            dispatch(documentUpdateFile(setLoading, jsonObj, customerId, "logo", partnerOrCustomer, setUpdate, setFileSelected, navigate));
                        }
                        else {
                            uploadDocApiCall(profilePicture, customerId, editData, setLoading, setFileSelected, dispatch, navigate)
                        }
                    }
                }
                navigate('/customer')
                if(response.message){
                    dispatch(setEmailUpdateSuccessMessage(true))
                }
                else{
                    dispatch(setEditSuccessMessage(true))
                }
            }
        } else {
            if (!response.error) {
                setLoading(false)
                if(response.message){
                    dispatch(setEmailUpdateSuccessMessage(true))
                }
                else{
                    dispatch(setEditSuccessMessage(true))
                }
                dispatch(fetchCustomer(setLoading,customerId))
                dispatch(fetchUpdateCustomerSuccess(response))
            }
        }
        if (response.error) {
            setLoading(false)
            toast.error(
                `${response.error.message}`
            )
            dispatch(fetchUpdateCustomerFailure(response.error.message))
        }
    }).catch((errorResp) => {
    })
}