import * as Yup from "yup"

export const validationSchemaForSimulate = Yup.object({
    eventType: Yup.string().required('Kindly enter Event Type'),
    uom: Yup.object().required('Kindly select UOM'),
    usageValue: Yup.number().typeError('It doesn\'t look like a number').positive('A number can\'t start with a minus').integer('It can\'t include a decimal point').required('Kindly enter Usage Value'),
    eventStartTime: Yup.date().required('Kindly Select start date'),
    eventEndTime: Yup.date().required('Kindly Select end date'),
})

export const validationSchemaForCustomer = (customerPermission, state) => {
    let validationSchema = {}
    return validationSchema = Yup.object({
        firstName: Yup.string().required("Kindly enter First Name").matches(/^[a-zA-Z ]*$/, 'Firstname can contain only characters'),
        lastName: Yup.string().required('Kindly enter Last Name').matches(/^[a-zA-Z ]*$/, 'Lastname can contain only characters'),
        userName: Yup.string().required('Kindly enter User Name'),
        email: Yup.string().email('Kindly enter valid email').required('Kindly enter Email'),
        // mobile: Yup.number().typeError('It doesn\'t look like a mobile number').positive('A phone number can\'t start with a minus').integer('It can\'t include a decimal point').test('len', 'Must be exactly 10 digits', val => val?.toString().length === 10).required('Kindly enter Phone Number'),
        // imsi: customerPermission.autoload ? Yup.number().required('Kindly enter IMSI').test('len', 'Must be exactly 15 digits', val => val?.toString().length === 15) : Yup.number(),
        accountNumber: customerPermission?.autoload ? Yup.string().required('Kindly enter Account Number').matches(/^[0-9a-zA-Z]+$/, 'Can contain only characters and digits').min(4,"Must be more than or equal to 4 characters").max(32,"Must be less than or equal to 32 characters") : Yup.string(),
        bdom:customerPermission?.autoload ? Yup.object().required('Kindly select Billing date of month') : Yup.object(),
        addressLine1: Yup.string().max(30, 'Must be 30 characters or less').required('Kindly enter Address'),
        country: Yup.object().required("Kindly select Country"),
        city: Yup.object().required('Kindly select City'),
        state: Yup.object().required('Kindly select State'),
        zipCode: Yup.number().typeError('It doesn\'t look like a zip code').positive('A zip code can\'t start with a minus').integer('It can\'t include a decimal point').required('Kindly enter Zip code'),
        documentType: state ? Yup.object().required('Kindly select document type') : Yup.object(),
        documentName: state ? Yup.string().required('Kindly enter the document name') : Yup.string(),
        selectedFile: state ? Yup.mixed().required('Kindly select a file') : Yup.mixed(),
    }, []);
}

export const validationSchemaForTelcoSimulate = Yup.object({
    subscriptionId:Yup.object().required('Kindly select the Subscription'),
    usageEventType: Yup.object().required('Kindly enter Event Type'),
    sessionId: Yup.string().required('Kindly enter Session Id'),
    sessionTime: Yup.date().required('Kindly select Session Time'),
    requestedUnits: Yup.number().typeError('It doesn\'t look like a number').positive('A number can\'t start with a minus').integer('It can\'t include a decimal point').required('Kindly enter Requested Units'),
    uom: Yup.object().required('Kindly select UOM'),
})