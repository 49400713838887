import React, { useEffect, useState, useMemo } from 'react'
import { Card, CardBody, CardHeader, Container } from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { Row, Col, Button } from 'reactstrap'
import { MaterialReactTable } from 'material-react-table';
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllReservation } from '../../slices/reservations/thunk';
import { getAllStation } from '../../slices/stations/thunk';
import { fetchCustomerListForReservation } from '../../slices/reservations/customerlist/thunk';
import { Loader } from '../Utils/Loader';
import { ToastContainer, toast } from 'react-toastify';
import { setSuccessMessage, setEditSuccessMessage } from '../../slices/toastMessage/action';
import moment from 'moment';
import useReservationColumns from './constants/coloumn';
import { PAGE_TITLES } from '../Common/constants';
import { useColumnSettings } from '../Utils/useColumnSettings';
import { getView } from '../../slices/View/thunk';

function ReservationListings() {
    const dispatch = useDispatch();
    const [station, setStation] = useState([])
    const [loading, setLoading] = useState(false)
    const allStationData = [];
    const allDate = [];
    const allName = [];
    const allNumber = [];
    const allstationadress = [];
    const allstationName = [];
    var flag = 0;
    var Stationflag = 0;
    var Stationflags = 0;
    const startDuration = [];
    const allDate1 = [];
    const endDuration = [];
    useEffect(() => {
        dispatch(getAllReservation())
        dispatch(getAllStation(setLoading))
        dispatch(fetchCustomerListForReservation(setLoading))
        dispatch(getView(setLoading));
    }, [dispatch])
    const reservation = useSelector((state) => state.Reservations.reservations)
    const stationData = useSelector((state) => state.Stations.station)
    const allres = useSelector((state) => state.CustomerAllData.customerListForReservation)
    const showSuccessMessage = useSelector((state) => state.SuccessToast.showSuccessMessage)
    const showEditSuccessMessage = useSelector((state) => state.SuccessToast.showEditSuccessMessage)

    const permissions = useSelector((state) => state.permissions.permissions);
    const reservationPermission = permissions.find((resource) => resource.resourceName === 'reservation');
    useEffect(() => {
        stationData.forEach((anotherElement) => {
            allStationData.push(anotherElement);
        })
        setStation(allStationData);
    }, [stationData])
    const csvConfig = mkConfig({
        fieldSeparator: ',',
        decimalSeparator: '.',
        useKeysAsHeaders: true,
    });
    const handleExportData = () => {
        const csv = generateCsv(csvConfig)(reservation);
        download(csvConfig)(csv);
    };
    const finalData = reservation.map((reservationItem, index) => {
        const startTimeMoment = moment.utc(reservationItem.StartTime);
        const endTimeMoment = moment.utc(reservationItem.EndTime);
        const startDate = startTimeMoment.format('D MMMM YYYY');
        const endDate = endTimeMoment.format('D MMMM YYYY');
        const startDurationValue = startTimeMoment.format('hh:mm A');
        const endDurationValue = endTimeMoment.format('hh:mm A');
        const customerData = allres.find(customer => customer.CustomerId === reservationItem.CustomerId);
        const stationData = station.find(stationItem => stationItem.stationDetails.stationID === reservationItem.StationId);
        return {
            reservationId: reservationItem.ReservationId,
            customerName: customerData?.FirstName || '',
            phoneNumber: customerData?.Mobile || '',
            stationNameAndAddress: stationData?.stationDetails.address || '',
            duration: `${startDurationValue} - ${endDurationValue}`,
            date: startDate,
            connectorType: reservationItem.ConnectorType,
            evseId: reservationItem.EvseId,
        };
    });
    reservation.forEach((res, index) => {
        const DateObj = moment(res.StartTime);
        const endDate = DateObj.format('D MMM YYYY');
        allDate[index] = endDate;
        startDuration[index] = DateObj.format('hh:mm A');
        const DateObj1 = moment(res.EndTime);
        const endDate1 = DateObj1.format('D MMM YYYY');
        allDate1[index] = endDate1;
        endDuration[index] = DateObj1.format('hh:mm A');
        flag = 0;
        Stationflag = 0;
        allres.forEach(customer => {
            if (res.CustomerId === customer.CustomerId) {
                flag = 1;
                allName[index] = customer?.Name || '-';
                allNumber[index] = customer.Mobile || '-';
            }
        });
        station.forEach(stationItem => {
            const matchingReservation = res.StationId === stationItem.stationDetails.stationID;
            if (matchingReservation) {
                if ("address" in stationItem.stationDetails) {
                    Stationflag = 1;
                    allstationadress[index] = stationItem.stationDetails.address;
                }
                if ("name" in stationItem.stationDetails) {
                    Stationflags = 1;
                    allstationName[index] = stationItem.stationDetails.name;
                }
            }
        });
        if (flag === 0) {
            allName[index] = "-";
            allNumber[index] = "-";
        }
        if (Stationflag === 0) {
            allstationadress[index] = "-";
        }
        if (Stationflags === 0) {
            allstationName[index] = '-';
        }
    });
    const navigate = useNavigate()
    const navigateToViewCustomer = async (id) => {
        let path = `/update-reservation/${id.original.reservationId}`
        navigate(path)
    }
    useEffect(() => {
        if (showSuccessMessage) {
            toast.success(`Reservation Created Successfully`);
            dispatch(setSuccessMessage(false))
        }
        if (showEditSuccessMessage) {
            toast.success(`Reservation Updated Successfully`);
            dispatch(setEditSuccessMessage(false))
        }
    }, [showSuccessMessage, showEditSuccessMessage, dispatch])
    const columns = useReservationColumns(allDate, allName, allNumber, allstationadress, startDuration, endDuration, allstationName, finalData, allres);

    const {
        settingsModalOpen,
        viewColumns,
        toggleSettingsModal,
        SettingsSidebar
    } = useColumnSettings('reservation', columns);

    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                <Container fluid>
                    <BreadCrumb title={PAGE_TITLES.RESERVATION_LIST} pageTitle={PAGE_TITLES.RESERVATION} />
                    <ToastContainer position='top-center' />
                    <Row>
                        <Col lg={settingsModalOpen ? 10 :12}>
                            <div className="listingjs-table " id="customerList">
                                <Card>
                                    <CardBody>
                                        <Row className=" mb-3 mt-1 table-responsive">
                                            {loading && (<Loader />)}
                                            {reservation ?
                                                <MaterialReactTable
                                                    columns={columns.filter((column) => viewColumns?.includes(column.accessorKey || column.header))}
                                                    data={finalData}
                                                    enableColumnOrdering
                                                    enableColumnDragging
                                                    enableRowActions
                                                    enableDensityToggle={false}
                                                    icons={{
                                                        DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                                                    }}
                                                    displayColumnDefOptions={{
                                                        'mrt-row-actions': {
                                                            header: '',
                                                            enableHiding: true,
                                                            size: 52,
                                                            grow: true
                                                        },
                                                    }}
                                                    defaultColumn={{
                                                        minSize: 10,
                                                        maxSize: 9001,
                                                        muiTableBodyCellProps: {
                                                            sx: {
                                                                fontFamily: "Inter,sans-serif"
                                                            }
                                                        },
                                                        muiTableHeadCellProps: {
                                                            sx: {
                                                                fontFamily: "Inter,sans-serif"
                                                            }
                                                        }
                                                    }}
                                                    // layoutMode='grid'
                                                    renderTopToolbarCustomActions={({ }) => (
                                                        <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between w-100">
                                                            <div className="d-flex flex-column flex-md-row">
                                                                <Button color="secondary" className="btn-label me-2 mb-2 mb-md-0" onClick={handleExportData}>
                                                                    <i className="ri-upload-line label-icon align-middle fs-16 me-2 "></i> Export Data
                                                                </Button>
                                                                {reservationPermission && reservationPermission.permissionList.includes('create') && <Button color="secondary" className="btn-label me-2" onClick={() => { navigate("/create-reservation") }}>
                                                                    <i className="ri-add-fill label-icon align-middle fs-16 me-2"></i> New Reservation
                                                                </Button>}
                                                            </div>
                                                            <div className="d-flex align-items-center">
                                                                <div color="secondary" className="btn-icon me-2 cursor-img" onClick={toggleSettingsModal}>
                                                                    <i className="ri-settings-2-line fs-4"></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                    renderRowActionMenuItems={({ closeMenu, row }) =>
                                                        [
                                                            reservationPermission && reservationPermission.permissionList.includes('update') ? <MenuItem key="view" onClick={() => { navigateToViewCustomer(row) }}>
                                                                <i className='ri  ri-account-circle-line me-2' />
                                                                Update
                                                            </MenuItem> : null
                                                        ]
                                                    }
                                                />
                                                : null
                                            }
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                        {SettingsSidebar()}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default ReservationListings;