import {
    Accordion,
    AccordionBody,
    AccordionHeader,
    AccordionItem,
    Button,
    Card,
    CardBody,
    CardHeader,
    Carousel,
    CarouselControl,
    CarouselIndicators,
    CarouselItem,
    Col,
    Label,
    Row,
} from "reactstrap";
import { customerIdData } from "./constants/constantFunctionsView";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { fetchDetailedBalance } from "../../slices/customer/detailedBalance/thunk";
import { Loader } from "../Utils/Loader";
import { useDispatch, useSelector } from "react-redux";
import { LabelText } from "../Utils/Input";
import InputField from "../Utils/InputField";
import { fetchAllSessions } from "../../slices/sessions/getAllSessions/thunk";
import { grantData, sessionJsonAndApiCall } from "./constants/SessionFunctions";
import { ToastContainer } from "react-toastify";
import { goToIndex, next, previous } from "./constants/corouselNavigation";
import { SessionCards } from "./SessionCards";
// import { goToIndex } from "./constants/SessionFunctions"

export const Sessions = () => {
    const subscriptionId = customerIdData();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const [updateRequestedUnit, setUpdateRequestedUnit] = useState([])
    const [updateUsedUnit, setUpdateUsedUnit] = useState([])
    const [terminateUsedUnit, setTerminateUsedUnit] = useState([])
    const customerId = location.state.customerId
    const fullName = location.state.fullName

    useEffect(() => {
        if (customerId && subscriptionId) {
            dispatch(fetchDetailedBalance(subscriptionId, setLoading, false))
            dispatch(fetchAllSessions(customerId, subscriptionId))
        }
    }, [subscriptionId, customerId]);

    const handleSessionChange = (e, index, unit, setUnit) => {
        const newUnits = [...unit];
        newUnits[index] = e.target.value;
        setUnit(newUnits);
    };

    let detailedBalanceData = useSelector(
        (state) => state.DetailedBalance.detailedBalance
    );
    let sessionData = useSelector((state) => state.GetAllSessions.sessions)
    let filteredGrantData = grantData(detailedBalanceData?.resources)

    const [open, setOpen] = useState({});
    const toggle = (index, id) => {
        setOpen((prev) => ({
            ...prev,
            [index]: prev[index] === id ? null : id,
        }));
    };

    return (
        <div className="page-content">
            {loading && <Loader />}
            <ToastContainer position="top-center" />
            <div className="page-title-box d-sm-flex justify-content-between align-items-center">
                <h4 className="mb-sm-0">USAGE SIMULATE</h4>
                <div>
                    <Button disabled={true} className="me-2">Live Session</Button>
                    <Button
                        onClick={() => {
                            navigate(`/simulate/${customerId}`, { state: { Name: fullName } });
                        }}
                    >
                        Add Session
                    </Button>
                </div>
            </div>
            <div className="page-title-box d-sm-flex justify-content-start align-items-center">
                <LabelText displayText={`Subscriber Name : ${fullName}`} classLabelName={"me-4"} />
                <LabelText displayText={`Subscription ID : ${subscriptionId}`} />
            </div>
            {filteredGrantData?.length && (
                <Card>
                    <CardHeader>
                        <h4 className="card-title mb-0 flex-grow-1">Balance</h4>
                    </CardHeader>
                    <CardBody>
                        {filteredGrantData.length > 1 ? <Carousel
                            activeIndex={activeIndex}
                            next={() => next(filteredGrantData, activeIndex, setActiveIndex, animating)}
                            previous={() => previous(filteredGrantData, activeIndex, setActiveIndex, animating)}
                            interval={false}
                        >
                            <CarouselIndicators
                                items={filteredGrantData}
                                activeIndex={activeIndex}
                                onClickHandler={(newIndex) => goToIndex(newIndex, animating, setActiveIndex)}
                            />
                            {filteredGrantData?.map((value) => (
                                <CarouselItem
                                    onExiting={() => setAnimating(true)}
                                    onExited={() => setAnimating(false)}
                                    key={value.resourceName}
                                >
                                    <SessionCards value={value}/>
                                </CarouselItem>
                            ))}
                            <CarouselControl
                                className="carousel-width"
                                direction="prev"
                                directionText="Previous"
                                onClickHandler={() => previous(filteredGrantData, activeIndex, setActiveIndex, animating)}
                            />
                            <CarouselControl
                                className="carousel-width"
                                direction="next"
                                directionText="Next"
                                onClickHandler={() => next(filteredGrantData, activeIndex, setActiveIndex, animating)}
                            />
                        </Carousel> : <SessionCards value={filteredGrantData[0]}/>}
                    </CardBody>
                </Card>
            )}
            {sessionData.map((value, index) => (
                <Accordion key={index} className="mb-4" open={open[index]} toggle={(id) => toggle(index, id)}>
                    <AccordionItem>
                        <div className="fw-semibold ms-0 mt-3 mb-1">
                            <Row className="d-flex justify-content-between align-items-end">
                                <Col>
                                    <div className="fw-bold mt-0 ms-3">
                                        {value?.usageEventType} Event - <span className="session-font-color">Session ID {value?.sessionId}</span>
                                    </div>
                                </Col>
                            </Row>
                            <hr className="w-100" />
                        </div>
                        <AccordionHeader
                            targetId={`1`}
                            className="mb-0 mt-n3 offer-name"
                        >
                            Update
                        </AccordionHeader>
                        <AccordionBody accordionId={`1`} >
                            <Row>
                                <Col lg={12}>
                                    <Row className="gy-4">
                                        <Col xxl={4} md={4}>
                                            <LabelText displayText={"Requested Units"} important={"*"} className={"text-danger"} classLabelName={"text-dark"} />
                                            <div className="d-flex justify-content-center align-items-center">
                                                <InputField
                                                    className={"me-2"}
                                                    placeholder={"Enter Requested Units"}
                                                    value={updateRequestedUnit[index]}
                                                    handleChange={(e) => {
                                                        const newUnit = [...updateRequestedUnit]
                                                        newUnit[index] = e.target.value
                                                        setUpdateRequestedUnit(newUnit)
                                                    }}
                                                    type={"number"}
                                                />
                                                <LabelText displayText={`${value?.uom}`} />
                                            </div>
                                        </Col>
                                        <Col xxl={4} md={4}>
                                            <LabelText displayText={"Used Units"} important={"*"} className={"text-danger"} classLabelName={"text-dark"} />
                                            <div className="d-flex justify-content-center align-items-center">
                                                <InputField
                                                    className={"me-2"}
                                                    placeholder={"Enter Used Units"}
                                                    value={updateUsedUnit[index]}
                                                    handleChange={(e) => { handleSessionChange(e, index, updateUsedUnit, setUpdateUsedUnit) }}
                                                    type={"number"}
                                                />
                                                <LabelText displayText={`${value?.uom}`} />
                                            </div>
                                        </Col>
                                        <Col xxl={2} md={2} />
                                        <Col xxl={2} md={2}>
                                            <Button className="mt-2" disabled={!(updateRequestedUnit[index] && updateUsedUnit[index])}
                                                onClick={() => {
                                                    sessionJsonAndApiCall(updateRequestedUnit[index], updateUsedUnit[index], terminateUsedUnit[index], value, customerId, dispatch, setLoading, "Update", setUpdateRequestedUnit, setUpdateUsedUnit, setTerminateUsedUnit)
                                                    setUpdateRequestedUnit((prevUnits) => {
                                                        const newUnits = [...prevUnits];
                                                        newUnits[index] = "";
                                                        return newUnits;
                                                    });

                                                    setUpdateUsedUnit((prevUnits) => {
                                                        const newUnits = [...prevUnits];
                                                        newUnits[index] = "";
                                                        return newUnits;
                                                    });
                                                }
                                                }>Update Session</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                        <AccordionHeader
                            targetId={`2`}
                            className="mb-0"
                        >
                            Terminate
                        </AccordionHeader>
                        <AccordionBody accordionId={`2`}>
                            <Row>
                                <Col lg={12}>
                                    <Row className="gy-4">
                                        <Col xxl={4} md={4}>
                                            <LabelText displayText={"Used Units"} important={"*"} className={"text-danger"} classLabelName={"text-dark"} />
                                            <div className="d-flex justify-content-center align-items-center">
                                                <InputField
                                                    className="me-2"
                                                    placeholder="Enter Used Units"
                                                    value={terminateUsedUnit[index]}
                                                    handleChange={(e) => handleSessionChange(e, index, terminateUsedUnit, setTerminateUsedUnit)}
                                                    type="number"
                                                />
                                                <LabelText displayText={`${value?.uom}`} />
                                            </div>
                                        </Col>
                                        <Col xxl={6} md={6} />
                                        <Col xxl={2} md={2}>
                                            <Button className="mt-2" disabled={!terminateUsedUnit[index]} onClick={() => {
                                                sessionJsonAndApiCall(updateRequestedUnit[index], updateUsedUnit[index], terminateUsedUnit[index], value, customerId, dispatch, setLoading, "Terminate", setUpdateRequestedUnit, setUpdateUsedUnit, setTerminateUsedUnit)
                                                setTerminateUsedUnit((prevUnits) => {
                                                    const newUnits = [...prevUnits];
                                                    newUnits[index] = "";
                                                    return newUnits;
                                                });
                                            }}>Terminate Session</Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </AccordionBody>
                    </AccordionItem>
                </Accordion>
            ))}

        </div>
    );
};
