import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    eventType: [],
    loading: false,
    error: null,
}

const eventTypeSlice = createSlice({
    name: "eventType",
    initialState,
    reducers: {
        fetchEventTypeSuccess: (state, action) => {
            state.loading = false,
                state.eventType = action.payload;
        },
        fetchEventTypeFailure: (state, action) => {
            state.loading = false,
                state.error = action.payload;
        },
    },
})

export const {
    fetchEventTypeSuccess,
    fetchEventTypeFailure,
} = eventTypeSlice.actions;

export default eventTypeSlice.reducer;