import React, { useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CardBody,
  Col,
} from "reactstrap";

const AggregateResourceCarousel = ({ resources = [] }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === resources.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const prevIndex = activeIndex === 0 ? resources.length - 1 : activeIndex - 1;
    setActiveIndex(prevIndex);
  };

  const slides = resources.map((resource, index) => (
    <CarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={index}
    >
      <div>
        <CardBody className="text-center mb-5 d-flex justify-content-center align-items-center">
          <Col className="mb-3 d-flex flex-column align-items-center">
            <div className="fs-4 fw-medium text-center">{resource.resourceName}</div>
            <div className="mt-1 text-center">
              <span className="aggregate-balance-resource fw-bold balance-heading-color">
                {resource.consumedBalance} {resource.uom}
              </span>
            </div>
            <div className="fs-5 fw-normal text-center">Consumed</div>
          </Col>
        </CardBody>
      </div>
    </CarouselItem>
  ));

  return (
    resources.length > 0 && (
      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
        <CarouselIndicators items={resources} activeIndex={activeIndex} onClickHandler={goToIndex} />
        {slides}
        {resources.length > 1 && (
          <>
            <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
            <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
          </>
        )}
      </Carousel>
    )
  );
};

export default AggregateResourceCarousel;
