import { fetchDeleteViewFailure, fetchDeleteViewSuccess } from "./reducer"
import { toast } from "react-toastify";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import {  VIEW_API } from "../../constants/constants";
import { getView } from "../thunk";

export const deleteView = (userId, setLoading, viewName, resetSelectedView) => (dispatch) => {
    setLoading(true);
    determineBaseUrlAndPerformApiCall(`${VIEW_API}/${userId}?viewName=${viewName}`, 'DELETE').then((response) => {
        if (!response.error) {
            dispatch(getView(setLoading));
            dispatch(fetchDeleteViewSuccess(response));
            setLoading(false);
            // resetSelectedView(); // Reset the selected view after delete
            toast.success('View Deleted Successfully');
        }
        if (response.error) {
            dispatch(fetchDeleteViewFailure(response.error.message));
            setLoading(false);
            toast.error(`${response.error.message}`);
        }
    }).catch((errorResp) => {
        setLoading(false);
        toast.error('Failed to delete the view');
    });
};
