import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    initiateSession: [],
    loading: false,
    error: null,
}

const initiateSessionSlice = createSlice({
    name: "initiateSession",
    initialState,
    reducers: {
        fetchInitiateSessionSuccess: (state, action) => {
            state.loading = false,
                state.initiateSession = action.payload;
        },
        fetchInitiateSessionFailure: (state, action) => {
            state.loading = false,
                state.error = action.payload;
        },
    },
})

export const {
    fetchInitiateSessionSuccess,
    fetchInitiateSessionFailure,
} = initiateSessionSlice.actions;

export default initiateSessionSlice.reducer;