export const formatValue = (value) => {
    if (typeof value === 'boolean') {
        return value ? "True" : "False";
    }
    if (value === undefined) {
        return "False"
    }
    return value;
};
export const createConfigArray = (finalValue,response) => {
    const config = {};

    const taskEnabledValue = finalValue["TaskEnabled"];
    const brmTaskEnabledValue = finalValue["BRMTaskEnabled"];

    const paymentProviderValue = finalValue["PaymentProvider"];
    const identityProviderValue = finalValue["IdentityProvider"]

    Object.entries(finalValue).forEach(([key, value]) => {
        let managementName = '';
        const managementNameMapping = {
            "payment": "PaymentManagement",
            "identity": "identityManagement",
            "whatsApp": "whatsAppManagement",
            "notification": "NotificationManagement",
            "Task": "TaskManagement",
            "policy": "policySpecifications",
            "customer": "customerRetrieval",
            "self": "selfcare",
            "order": "orderManagement"
        };
        function getManagementName(key) {
            let managementName = null;
        
            Object.entries(managementNameMapping).forEach(([prefix, name]) => {
                if(prefix === "Task" && key.includes(prefix)){
                    managementName = name
                }
                else if (key.startsWith(prefix)) {
                    managementName = name;
                }
            });
        
            return managementName;
        }

        managementName = getManagementName(key)
        if (managementName) {
            if (!config[managementName]) {
                config[managementName] = {
                    name: managementName,
                    value: [],
                    operatorId: response?.data?.adminDetails?.operatorId,
                    type: response?.data?.domain,
                    level: "Operator"
                };
            }

            const managementConfig = {
                "TaskManagement": [
                    { key: "TaskEnabled", value: formatValue(taskEnabledValue) },
                    { key: "BRMTaskEnabled", value: formatValue(brmTaskEnabledValue) }
                ],
                "PaymentManagement": [
                    { key: "PaymentProvider", value: paymentProviderValue },
                    { key: "PaymentStatus", value: value ? "ACTIVE" : "INACTIVE" }
                ],
                "identityManagement": [
                    { key: "IdentityProvider", value: identityProviderValue },
                    { key: "Enable", value: value ? "True" : "False" }
                ],
                "orderManagement": [
                    { key: "OrderEnabled", value: value ? "True" : "False" }
                ],
                "NotificationManagement" : [
                    { key: "NotificationEnable", value: value ? "True" : "False" }
                ]
            };

            if (managementConfig[managementName]) {
                managementConfig[managementName].forEach(item => {
                    if (!config[managementName].value.some(existingItem => existingItem.key === item.key)) {
                        config[managementName].value.push({
                            key: item.key,
                            value: [item.value]
                        });
                    }
                });
            } else {
                const formattedValue = formatValue(value);
                if (!config[managementName].value.some(item => item.key === key)) {
                    config[managementName].value.push({
                        key: key,
                        value: [formattedValue]
                    });
                }
            }

        }
    });
    return Object.values(config);
};