import { GET_ALL_SESSIONS } from "../../constants/constants"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor"
import { fetchSessionFailure, fetchSessionSuccess } from "./reducer"

export const fetchAllSessions = (customerId, subscriptionId) => async (dispatch) => {
    await determineBaseUrlAndPerformApiCall(`${GET_ALL_SESSIONS}/${customerId}${subscriptionId ? `?subscriptionId=${subscriptionId}` : ''}`, 'GET').then((response) => {
        if (response?.error) {
            dispatch(fetchSessionFailure(response.error.message))
        }
        if (!response?.error) {
            dispatch(fetchSessionSuccess(response.reverse()))
        }
    })
}