import { useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';

export const CreatableSelectField = ({
  name,
  options,
  isMulti,
  values,
  handleChange,handleBlur,
  placeholder,
  onKeyDown,
  fieldLabel,
  fieldName,
  setFieldError,
  setFieldValue,
  errors,
  touched,
  valuess,isClearable,isDisabled,
  isAttribute, className
}) => {

  const layoutModeType = useSelector((state) => state.Layout.layoutModeType);
  
  const customStyles = {
    control: (styles, { isFocused, errors, touched }) => ({
      ...styles,
      borderRadius: '0.5rem',
      borderColor: layoutModeType === "dark" ? (isFocused ? "#0078A3" : "#495057") : "#ced4da",// Apply red border if there is an error, otherwise handle focused state
      backgroundColor: layoutModeType === "dark" ? "#212529" : "white", 
      color: layoutModeType === "dark" ? "white" : "black",
      '&:hover': {
        borderColor: errors && touched ? 'red' : isFocused ? '#0078A3' : (layoutModeType === "dark" ? "#495057" : "#ced4da"),
      }
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? "white" : provided.color,
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: layoutModeType === "dark" ? "#212529" : "#0078A3",
      color: "white",
    }),
  };
  
  
  const handleSelectChange = (selectedOptions) => {
    if (fieldName === "SelfcareAttribute" && selectedOptions.length > 2) {
      setFieldError(name, "You can select only up to 2 options");
    } else {
      // setFieldError(name, ""); 
      setFieldValue(name, selectedOptions); 
    }
  };

  return (
    <>
      <CreatableSelect
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: layoutModeType === "dark" ? "#495057" : "#015E7F",
            primary25: layoutModeType === "dark" ? "#343a40" : "#015E7F",
            primary50: layoutModeType === "dark" ? "#495057" : "#015E7F",
            primary75: layoutModeType === "dark" ? "#495057" : "#015E7F",
          },
        })}
        className={className}
        value={values}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        onChange={isAttribute ? handleSelectChange : handleChange}
        onBlur={handleBlur}
        styles={customStyles}
        name={name}
        isMulti={isMulti}
        options={options}
        isClearable={isClearable}
        isDisabled={isDisabled}
        noOptionsMessage={() => "Create a new option"}
      />
      {touched && errors && (
        <div className="text-danger mt-1 fs-6">{errors}</div>
      )}
    </>
  );
};
