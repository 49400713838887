import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
    detailedBalance : [],
    loading : false,
    error : null,
};

const detailedBalanceSlice = createSlice({
    name:"detailedBalance",
    initialState,
    reducers: {
        fetchDetailedBalanceSuccess: (state,action) => {
            state.loading = false;
            state.detailedBalance = action.payload
        },
        fetchDetailedBalanceFailure: (state,action) => {
            state.loading = false;
            state.error = action.payload
        }
    }
})

export const {
    fetchDetailedBalanceFailure,
    fetchDetailedBalanceSuccess
} = detailedBalanceSlice.actions

export default detailedBalanceSlice.reducer