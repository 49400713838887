import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateSession: [],
    loading: false,
    error: null,
}

const updateSessionSlice = createSlice({
    name: "updateSession",
    initialState,
    reducers: {
        fetchUpdateSessionSuccess: (state, action) => {
            state.loading = false,
                state.updateSession = action.payload;
        },
        fetchUpdateSessionFailure: (state, action) => {
            state.loading = false,
                state.error = action.payload;
        },
    },
})

export const {
    fetchUpdateSessionSuccess,
    fetchUpdateSessionFailure,
} = updateSessionSlice.actions;

export default updateSessionSlice.reducer;