import React from "react"
import { Container, Card, Row, Col, CardBody, CardHeader, Button, FormFeedback } from "reactstrap";
import { ErrorMessage } from "formik"
import InputField from "../Utils/InputField";
import SelectField from "../Utils/SelectField";
import { LabelText } from "../Utils/Input";
import { handleChangeForEndDateOfUom, handleChangeForStartDateOfUom, handleChangeForUom } from "./constants/HandleChangeFunctions";
import CommonButton from "../Utils/CommonButton";
import CommonDateTimePicker from "../Utils/CommonDateTimePicker";
import moment from "moment";

export default function SimulatePage({
    touched, row,
    errors,
    values,
    setFieldTouched, setStartDate,
    setFieldValue,
    handleBlur,
    handleChange,
    options,
    startDate,
    setSelectedUom,
    handleStartDateChange
}) {
    return (
        <Container fluid>
            <Row>
                <Col lg={12} xs={4} md={9}>
                    <Card>
                        <CardHeader className="d-flex align-items-center">
                            <h4 className="card-title mb-0">Usage</h4>
                        </CardHeader>
                        <CardBody className="card-body">
                            <div className="live-preview">
                                <Row className="gy-4">
                                <Col xxl={4} md={4}>
                                        <LabelText htmlFor={"eventType"} className={"text-danger"} displayText={"Event Type"} important={"*"} />
                                        <InputField name={"eventType"} placeholder={"Enter event type"} type={"text"} className={"form-control"} id={"eventType"} handleChange={handleChange} handleBlur={handleBlur} value={values.eventType}
                                            invalid={touched.eventType && errors.eventType
                                                ? true
                                                : false} />
                                        {touched.eventType &&
                                            errors.eventType ? (
                                            <FormFeedback type="invalid">
                                                {errors.eventType}
                                            </FormFeedback>
                                        ) : null}

                                    </Col>
                                    <Col xxl={4} md={4}>
                                        <LabelText htmlFor={"uom"} className={"text-danger"} displayText={"UOM"} important={"*"} />
                                        <SelectField options={options}
                                            className={(errors.uom && touched.uom) ? 'error-input' : ''}
                                            value={values.uom || ""}
                                            handleChange={handleChangeForUom(setSelectedUom, setFieldTouched, setFieldValue)}
                                        />
                                        {touched.uom && errors.uom ? (
                                            <p className="text-danger">
                                                {errors.uom}
                                            </p>
                                        ) : null}
                                    </Col>
                                    <Col xxl={4} md={4}>
                                        <LabelText htmlFor={"usageValue"} className={"text-danger"} displayText={"Usage Value"} important={"*"} />
                                        <InputField name={"usageValue"} placeholder={"Enter usage value"} type={"text"} className={"form-control"} id={"usageValue"} handleChange={handleChange} handleBlur={handleBlur} value={values.usageValue}
                                            invalid={touched.usageValue && errors.usageValue
                                                ? true
                                                : false} />
                                        {touched.usageValue &&
                                            errors.usageValue ? (
                                            <FormFeedback type="invalid">
                                                {errors.usageValue}
                                            </FormFeedback>
                                        ) : null}

                                    </Col>
                                    <Col xxl={4} md={4}>
                                        <LabelText htmlFor={"eventStartTime"} className={"text-danger"} displayText={"Start Time"} important={"*"} />
                                        <CommonDateTimePicker
                                            className={`form-control ${touched.eventStartTime &&
                                                !values.eventStartTime ? (
                                                "error-input"
                                            ) : ""}`}
                                            value={values.eventStartTime}
                                            name={`eventStartTime`}
                                            format="DD/MM/YYYY hh:mm A"
                                            handleChange={handleChangeForStartDateOfUom(values, handleStartDateChange, setStartDate, setFieldTouched)}
                                        />
                                        {errors.eventStartTime &&
                                            touched.eventStartTime &&
                                            !values.eventStartTime && (
                                                <p className="text-danger error">
                                                    {" "}
                                                    {errors.eventStartTime}{" "}
                                                </p>
                                            )}
                                    </Col>
                                    <Col xxl={4} md={4}>
                                        <LabelText htmlFor={"eventEndTime"} className={"text-danger"} displayText={"End Time"} important={"*"} />
                                        <CommonDateTimePicker
                                            value={values.eventEndTime}
                                            handleChange={handleChangeForEndDateOfUom(values, setFieldTouched)}
                                            className={`form-control ${touched.eventEndTime &&
                                                !values.eventEndTime ? (
                                                "error-input"
                                            ) : ""}`}
                                            format="DD/MM/YYYY hh:mm A"
                                            name={`eventEndTime`}
                                            minDateTime={values.eventStartTime}
                                        />
                                        {errors.eventEndTime &&
                                            touched.eventEndTime &&
                                            !values.eventEndTime && (
                                                <p className="text-danger error">
                                                    {" "}
                                                    {errors.eventEndTime}{" "}
                                                </p>
                                            )}
                                    </Col>
                                </Row>
                                <Row lg={6} className=" justify-content-end mb-4 mt-4">
                                    <div className="live-preview">
                                        <div className="d-flex justify-content-end gap-2 ">
                                            <CommonButton outline={true} className={"cancel-button-background"} color={"main-color"} type={"reset"} buttonAction={() => row.toggleExpanded(false)} buttonText={"Cancel"} />
                                            <CommonButton color={"main-color"} type={'submit'} buttonText={"Confirm"} />
                                        </div>
                                    </div>
                                </Row>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}