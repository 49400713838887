import { Input } from "reactstrap";

export const uomColoumns = (selectAllUoms, setSelectAllUoms, uom, setSelectedUom, selectedUom, checkboxRef, isMobile) => {
  const columns = [
    {
      accessorKey: 'checkbox',
      header: 'checkbox',
      Header: ({ row }) => (
        <Input readOnly type="checkbox" ref={checkboxRef} className=' border-black checkbox-size rounded-1' checked={selectAllUoms} style={{ borderWidth: '2px', borderColor: '#000', borderRadius: '0' }} onClick={() => {
          setSelectAllUoms(!selectAllUoms)
          if (!selectAllUoms) {
            setSelectedUom(uom)
          }
          else {
            setSelectedUom([])
          }
        }} />
      ),
      enableColumnActions: false,
      enableColumnDragging: false,
      Cell: ({ row }) => (
        <Input readOnly type="checkbox" className=' border-black checkbox-size  rounded-1' checked={selectedUom.some(p => p.name === row.original.name)} style={{ borderWidth: '2px', borderColor: '#000', borderRadius: '0' }} onClick={() => {
          handleCheckboxChange(row, selectedUom, setSelectedUom)
        }
        } />
      ),
      size: isMobile ? 50 : 7
    },
    {
      accessorKey: 'name',
      header: 'Name',
    },
    {
      accessorKey: 'valueType',
      header: 'Value Type',
      Cell: ({cell}) => cell.getValue() === "bool" ? 'Boolean' : cell.getValue() === "float64" ? "Decimal" : cell.getValue() === "string" ? "Text" : cell.row.original.valueType[0].toUpperCase() + cell.row.original.valueType.slice(1)
    },
  ];

  return columns;
};

export const handleCheckboxChange = (row, selectedUom, setSelectedUom) => {
  let isChecked = selectedUom.some(p => p.name === row.original.name);
  let updatedSelectedUoms = isChecked
    ? selectedUom.filter(p => p.name !== row.original.name)
    : [...selectedUom, row.original];
  setSelectedUom(updatedSelectedUoms);
};