import { toast } from "react-toastify"
import { INITIATE_SESSION } from "../../constants/constants"
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor"
import { fetchInitiateSessionFailure, fetchInitiateSessionSuccess } from "./reducer"

export const initiateSession = (jsonObj, setLoading,navigate,customerId,fullName) => async (dispatch) => {
    await determineBaseUrlAndPerformApiCall(`${INITIATE_SESSION}`, 'POST',jsonObj).then((response) => {
        setLoading(true)
        if (response?.error) {
            setLoading(false)
            toast.error(
                `${response.error.message}`
            )
            dispatch(fetchInitiateSessionFailure(response.error.message))
        }
        if (!response?.error) {
            setLoading(false)
            dispatch(fetchInitiateSessionSuccess(response))
            navigate(`/sessions/${response?.subscriptionId}`,{state:{customerId:customerId, fullName:fullName}})  
        }
    })
}