import { createAttribute } from "../../../../slices/attribute/createattribute/thunk";
import { updateAttribute } from "../../../../slices/attribute/updateattribute/thunk";

export const onSubmit = (dispatch, setLoading, navigate, isEdit, attribute, checked, mandatoryChecked,mandatoryCheckedSelfcare) => (values) => {
    const jsonObj = AttributeJsonOnj(values, checked, mandatoryChecked,mandatoryCheckedSelfcare);
    submitAttribute(jsonObj, dispatch, setLoading, navigate, isEdit, attribute);
};
export const submitAttribute = (jsonObj, dispatch, setLoading, navigate, isEdit, attribute) => {
    if (isEdit) {
        dispatch(updateAttribute(setLoading, attribute, jsonObj, navigate))
    }
    else {
        dispatch(createAttribute(jsonObj, setLoading, navigate));
    }
};

export const AttributeJsonOnj = (values, checked, mandatoryChecked,mandatoryCheckedSelfcare) => {
    const valueArray = values?.attributeValue && values?.attributeValue?.map(item => item.value);
    const selfcareValueArray = values?.SelfcareAttribute && values?.SelfcareAttribute?.map(item => item.value);
    const finalValuesArray = selfcareValueArray?.length ? selfcareValueArray :
    valueArray?.length ? valueArray :
    [];
    const jsonObj = JSON.stringify({
        name: values?.name,
        description: values.description,
        type: values?.type.value,
        valueType: values?.valueType.value,
        default: values.defaultValue ? values.defaultValue.toString() : "0",
        minValue: values.minimumValue ? values.minimumValue.toString() : "0",
        maxValue: values.maximumValue ? values.maximumValue.toString() : "0",
        values: finalValuesArray,
        searchable: checked,
        mandatory: mandatoryChecked,
        selfcareVisible: mandatoryCheckedSelfcare
    })
    return jsonObj
}

export const navigateToAddAttribute = (navigate) => {
    navigate("/create-attribute")
}