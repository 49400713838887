import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { GET_ALL_TASKS } from "../../constants/constants";
import { fetchGetAllTasksSuccess, fetchGetAllTasksFailure,resetGetAllTasks } from "./reducer";

export const fetchAllTasks = (setLoading,id) => async (dispatch) => {
    try {
        setLoading(true)
        const response = await determineBaseUrlAndPerformApiCall(`${GET_ALL_TASKS}${id ? `?referenceid=${id}`: ""}`,'GET')
        setLoading(false)
        dispatch(resetGetAllTasks())
        dispatch(fetchGetAllTasksSuccess(response.reverse()))
    }
    catch (error) {
        setLoading(false)
        dispatch(fetchGetAllTasksFailure(error.message))
    }
}