import React from "react";
import { Card, CardHeader, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { LabelText } from "../../Utils/Input";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { MaterialReactTable } from 'material-react-table';
import moment from "moment";

export default function OfferPreview({
    row, activeTab, setActiveTab,
    tabChange,
    termValidity,
    grantsColumn, chargesColumn, usageRatePlanColumn
}) {
    return (
        <Card key={row.index}>
            <CardHeader>
                <div className="d-flex justify-content-between align-items-center ">
                    <h4>{row.original.offerName}</h4>
                    <Link><i data-testid="close" className="ri-close-fill fs-2 me-2 text-black " onClick={() => !row.toggleExpanded()}></i></Link>
                </div>
                <h6>{row.original.offerCode}</h6>
            </CardHeader>
            <CardBody>
                <div className='d-flex flex-column justify-content-between'>
                    <LabelText displayText={`Offer Validity : ${moment.utc(row?.original?.validity?.from).format("DD MMM, YYYY")} - ${moment.utc(row?.original?.validity?.to).format("DD MMM, YYYY")}`} />
                    {(row.original.subscriptionValidity.relativeEndTimeUnit === 0 && row.original.subscriptionValidity.relativeEndTimeValue === 0)  ?  <LabelText displayText={`Term Validity : 0`} /> : <LabelText displayText={`Term Validity : ${row.original.subscriptionValidity.endTimeType === 1 ? "Never Ending" : `${termValidity(row.original.subscriptionValidity.relativeEndTimeUnit, row.original.subscriptionValidity.relativeEndTimeValue).props.children[0]}${termValidity(row.original.subscriptionValidity.relativeEndTimeUnit, row.original.subscriptionValidity.relativeEndTimeValue).props.children[1]}`}`} />}
                    <LabelText displayText={`Global Offer : ${row.original.globalScope === false ? "False" : "True"} `} />
                    {row.original.serviceCode && (<LabelText displayText={`Service Code : ${row.original.serviceCode} `} />)}
                </div>
            </CardBody>
            <CardHeader>
                <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                    {row.original.Grants && (
                        <NavItem>
                            <NavLink
                                className={classnames({ active: activeTab === `${row.index}.1` })}
                                onClick={() => {
                                    tabChange(`${row.index}.1`, activeTab, setActiveTab);
                                }}>
                                <i className="fas fa-home"></i>
                                Grants
                            </NavLink>
                        </NavItem>
                    )}
                    {"Charges" in row.original && (
                        <NavItem>
                            <NavLink to="#"
                                className={classnames({ active: activeTab === `${row.index}.2` })}
                                onClick={() => {
                                    if (!("Grants" in row.original)) {
                                        tabChange(`${row.index}.1`, activeTab, setActiveTab);
                                    }
                                    else {
                                        tabChange(`${row.index}.2`, activeTab, setActiveTab);
                                    }
                                }}
                                type="button">
                                <i className="far fa-user"></i>
                                Charges
                            </NavLink>
                        </NavItem>
                    )}
                    {"usageRatePlan" in row.original && (
                        <NavItem>
                            <NavLink to="#"
                                className={classnames({ active: activeTab === `${row.index}.3` })}
                                onClick={() => {
                                    if (!("Grants" in row.original) && !("Charges" in row.original)) {
                                        tabChange(`${row.index}.1`, activeTab, setActiveTab);
                                    }
                                    else if (!("Charges" in row.original)) {
                                        tabChange(`${row.index}.2`, activeTab, setActiveTab);
                                    }
                                    else {
                                        tabChange(`${row.index}.3`, activeTab, setActiveTab);
                                    }
                                }}
                                type="button">
                                <i className="far fa-user"></i>
                                Usage Rate Plan
                            </NavLink>
                        </NavItem>
                    )}
                </Nav>
            </CardHeader>
            <CardBody>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={`${row.index}.1`}>
                        <React.Fragment>
                            {row.original.Grants && row.original.Grants.length > 0 && (
                                <MaterialReactTable
                                    data={row.original.Grants}
                                    columns={grantsColumn}
                                    enableDensityToggle={false}
                                    icons={{
                                        DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                                    }}
                                    muiTableHeadCellProps={{
                                        sx: {
                                            fontFamily: "Inter,sans-serif"
                                        }
                                    }}
                                    muiTableBodyCellProps={{
                                        sx: {
                                            fontFamily: "Inter,sans-serif"
                                        }
                                    }}
                                />)
                            }
                        </React.Fragment>
                    </TabPane>
                    <TabPane tabId={!("Grants" in row.original) ? `${row.index}.1` : `${row.index}.2`}>
                        {row.original.Charges && (
                            <MaterialReactTable
                                data={row.original.Charges}
                                columns={chargesColumn}
                                enableDensityToggle={false}
                                icons={{
                                    DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                                }}
                                muiTableHeadCellProps={{
                                    sx: {
                                        fontFamily: "Inter,sans-serif"
                                    }
                                }}
                                muiTableBodyCellProps={{
                                    sx: {
                                        fontFamily: "Inter,sans-serif"
                                    }
                                }}
                            />)
                        }
                    </TabPane>
                    <TabPane tabId={!("Grants" in row.original) && !("Charges" in row.original) ? `${row.index}.1` : !("Charges" in row.original) ? `${row.index}.2` : `${row.index}.3`}>
                        {
                            row.original.usageRatePlan && (
                                <MaterialReactTable
                                    data={row.original.usageRatePlan}
                                    columns={usageRatePlanColumn}
                                    enableDensityToggle={false}
                                    icons={{
                                        DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                                    }}
                                    muiTableHeadCellProps={{
                                        sx: {
                                            fontFamily: "Inter,sans-serif"
                                        }
                                    }}
                                    muiTableBodyCellProps={{
                                        sx: {
                                            fontFamily: "Inter,sans-serif"
                                        }
                                    }}
                                />
                            )
                        }
                    </TabPane>
                </TabContent>
            </CardBody>
        </Card>
    )
}