import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    updateConfig: [],
    loading: false,
    error: null,
}

const updateConfigOperatorSlice = createSlice({
    name: "updateConfig",
    initialState,
    reducers: {
        fetchUpdateConfigOperatorSucess: (state, action) => {
            state.loading = false
            state.updateConfig = action.payload;
        },
        fetchUpdateConfigOperatorFailure: (state, action) => {
            state.loading = false
            state.error = action.payload;
        },
    },
})

export const {
    fetchUpdateConfigOperatorSucess,
    fetchUpdateConfigOperatorFailure,
} = updateConfigOperatorSlice.actions;

export default updateConfigOperatorSlice.reducer;