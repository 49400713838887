import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap"

export default function CommonModal({ toggle, open, messageForPopUp, buttonText, modalAction,closeButtonText,fromCustomer,closeButtonAction,dontShowCloseButton,newLineMessage}) {
    return (
        <Modal backdrop="static" centered={true} toggle={toggle} isOpen={open}>
            <ModalHeader toggle={toggle} className="d-flex justify-content-end  align-items-center"></ModalHeader>
            <ModalBody className="fs-5 fw-bold text-center">{messageForPopUp}{newLineMessage ? <div>{newLineMessage}</div> :''}</ModalBody>
            <ModalFooter className="d-flex justify-content-center align-items-center ">
                {fromCustomer !== "Customer" ? !dontShowCloseButton && <Button onClick={toggle} className="d-flex justify-content-center align-items-center bg-transparent text-danger border-white shadow-none ">
                    <i className="ri-close-fill" />
                    Close
                </Button> :  
                <Button onClick={closeButtonAction} >
                    {closeButtonText}
                </Button>}
                <Button onClick={modalAction} >
                    {buttonText}
                </Button>
            </ModalFooter>
        </Modal>
    )
}