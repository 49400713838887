import moment from "moment";
import { OPERATOR_GET_OPERATOR_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { fetchOperatorIdFailure, fetchOperatorIdSuccess } from "./reducer";

export const fetchOperatorbyId = (setLoading,operatorId,initialValues,operatorOptions,OperatorStatusOptions,setEditData) => async (dispatch) => {
    try {
        setLoading(true)
        const response = await determineBaseUrlAndPerformApiCall(`${OPERATOR_GET_OPERATOR_API}/${operatorId}`,'GET')
        setLoading(false)
        setEditData(response)
        dispatch(fetchOperatorIdSuccess(response))
        initialValues.businessName = response?.tradingName || "",
        initialValues.Domain = operatorOptions?.find(optr => optr?.name === 'Domain')?.options?.find(option => option?.value === response?.domain);
        initialValues.state =  {value: response?.state, label: response?.state},
        initialValues.city =  {value: response?.city, label: response?.city},
        initialValues.zipCode =  response?.postcode,
        initialValues.endDate = moment(response?.existsDuring?.endDateTime).utc(),
        initialValues.Address = response?.street1,
        initialValues.Address2 = response?.street2,
        initialValues.country= {value: response?.country, label: response?.country}
        initialValues.firstname= response?.adminDetails?.profile?.firstName
        initialValues.lastname=response?.adminDetails?.profile?.lastName
        initialValues.username=response?.adminDetails?.userName
        initialValues.phone= response?.adminDetails?.dialCode + response?.adminDetails?.phone
        initialValues.email= response?.adminDetails?.email
        initialValues.Status=  OperatorStatusOptions?.find(option => option?.value === response?.status)
        // initialValues.attributeList= response?.partyCharacteristic
    }
    catch (error) {
        setLoading(false)
        dispatch(fetchOperatorIdFailure(error.message))
    }
}