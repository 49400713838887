import React from "react";
export const renderStatusBadge = (status) => {
    switch (status) {
        case 'inactive':
            return <span className="badge bg-warning-subtle text-warning">Inactive</span>;
        case 'active':
            return <span className="badge bg-success-subtle text-success">Active</span>;
        default:
            return <span className="badge bg-success-subtle text-success">Active</span>;
    }
};