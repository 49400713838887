import { addresource } from "../../../../slices/resources/addresources/thunk";
import { editResource } from '../../../../slices/resources/updateresource/thunk';

export const handleSubmit = ({
    values,
    aggregate,
    isChecked,
    isCounter,
    isEdit,
    resourceName,
    dispatch,
    setLoading,
    navigate,
}) => {
    const aggregateLabels = aggregate && values?.aggregateResource
        ? values.aggregateResource.map(resource => resource.label)
        : [];

    const thresholdType = values?.thresholds[0]?.percentage === '' ? 'Fixed' : 'Percentage';
    const thresholdArray = values?.thresholds?.map(threshold => {
        const thresholdValue = threshold.thresholdValue ? parseInt(threshold.thresholdValue) : null;
        const percentageValue = threshold.percentage ? parseInt(threshold.percentage) : null;
        return thresholdValue !== null ? thresholdValue : percentageValue;
    }).filter(value => value !== null) || [];

    const jsonObj = JSON.stringify({
        ...(aggregate !== true && { isCurrency: values?.isCurrency?.value }),
        name: values?.name,
        resourceId: values?.resourceId,
        ...(values?.isCurrency?.value && { currencyCode: values?.currencyCode?.value }),
        ...(aggregate !== true && {
            ceiling: parseInt(values?.ceiling) || null,
            floor: parseInt(values?.floor) || null,
        }),
        unitofmeasurement: values?.uom?.value,
        override: isChecked,
        isCounter: isCounter,
        isAggregate: aggregate,
        ...(aggregateLabels.length > 0 && { aggregates: aggregateLabels }),
        ...(thresholdArray.length > 0 && {
            thresholdType,
            threshold: thresholdArray,
        }),
    });

    if (!isEdit) {
        dispatch(addresource(jsonObj, setLoading, navigate));
    } else {
        dispatch(editResource(jsonObj, resourceName, setLoading, navigate));
    }
};
