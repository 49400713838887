import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardTitle, CardText } from 'reactstrap';
import ProfileImage from '../../assets/images/users/user-dummy-img.jpg';
import { viewDoc } from '../../slices/customer/documentview/thunk';
import { useSelector } from 'react-redux';

export default function CustomerProfileCard({ customerData, dispatch, customerId, setLoading }) {
    const [profileData, setProfileData] = useState(false)
    useEffect(() => {
        let partnerOrCustomer = "Customer"
        dispatch(viewDoc(setLoading, customerId, "logo", partnerOrCustomer, setProfileData));
    }, [dispatch])
    let base64ImageData = []
    let profile = useSelector((state) => state.ViewDocument.documentView)

    base64ImageData = profile?.filePath

    return (
        <Card className="shadow-sm flex-fill card-profile">
            {/* <div className="svg-container">
                <svg className="svg-wave1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path fill="#e5f1f5" fillOpacity="1" d="M0,160 C320,240 720,50 1440,100 L1440,320 L0,320 Z"></path>
                </svg>
                <svg className="svg-wave2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
                    <path fill="#ffffff" fillOpacity="1" d="M0,240 C480,320 960,200 1440,240 L1440,320 L0,320 Z"></path>
                </svg>
            </div> */}
            <CardBody className="card-body-custom">
                <div className="avatarr mb-3">
                    <img
                        src={profileData ? base64ImageData :ProfileImage}
                        alt="Profile"
                        className="rounded-circle"
                        width={100}
                    />
                </div>
                <CardTitle className='fw-medium'>
                    {customerData?.customerInfo?.firstName} {customerData?.customerInfo?.lastName}
                </CardTitle>
                <div className="mt-4">
                    {/* Contact Number Row */}
                    <div className="d-flex justify-content-between px-4 align-items-center">
                        <div className="fw-medium large-text">Contact Number</div>
                        <div className="fw-semibold font-weight-bold large-text">
                            {customerData?.customerInfo?.dialCode}-{customerData?.customerInfo?.mobile}
                        </div>
                    </div>
                    {/* Account Number Row */}
                    <div className="d-flex justify-content-between px-4 mt-3 align-items-center">
                        <div className="fw-medium large-text">Account Number</div>
                        <div className="fw-semibold font-weight-bold large-text">
                            {customerData?.customerInfo?.customerId}
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}