import React, { useState } from 'react';
import { Card, Label, CardHeader, CardBody, Nav, NavItem, NavLink, TabContent, TabPane, Table, Accordion, AccordionItem, AccordionHeader, AccordionBody, Progress } from "reactstrap";
import classnames from "classnames";
import { LabelText } from '../Utils/Input';
import { Link } from "react-router-dom";
import { TablesWithoutBorders } from "../../pages/Tables/BasicTables/BasicTablesCode";
import { useSelector } from "react-redux";
import { MaterialReactTable } from "material-react-table";
import { attributeColumnData } from "./constants/ListingCustomerFunctions";

export const CustomerPreview = ({
    customerData, row, activeTab, setActiveTab, tabChange, noCurrencyArray, currencyArray, fetchCustomerSummaryData,
    reservationDataArray, duration, subscriptionsArray, customerAttributeData,
    convertNumberOfDaysToMonthYear, getDayDiff, current, formatDate
}) => {
    const [openAccordion, setOpenAccordion] = useState('');
    const toggleAccordion = (id) => {
        setOpenAccordion(openAccordion === id ? '' : id);
    };
    const permissions = useSelector((state) => state.permissions.permissions);
    const reservationPermission = permissions.find((resource) => resource.resourceName === 'reservation');
    const subscriptionPermission = permissions.find((resource) => resource.resourceName === 'subscription');
    const attributeColumn = attributeColumnData();
    return (
        <Card>
            <div className="d-flex justify-content-between align-items-center ">
                <Label className=' p-2 fs-2'>{customerData.customerInfo?.firstName} {customerData.customerInfo?.lastName}</Label>
                <Link><i data-testid="close" className="ri-close-fill fs-2 me-2 text-black " onClick={() => !row.toggleExpanded()}></i></Link>
            </div>
            <CardHeader>
                <Nav className="nav-tabs-custom rounded card-header-tabs border-bottom-0" role="tablist">
                    <NavItem>
                        <NavLink
                            className={` fw-semibold fs-5 ${classnames({ active: activeTab === `${row.index}.1` })}`}
                            onClick={() => {
                                tabChange(`${row.index}.1`, activeTab, setActiveTab);
                            }}>
                            <i className="fas fa-home"></i>
                            Resource
                        </NavLink>
                    </NavItem>
                    {reservationPermission && (
                        <NavItem>
                            <NavLink to="#"
                                className={` fw-semibold fs-5 ${classnames({ active: activeTab === `${row.index}.2` })}`}
                                onClick={() => {
                                    tabChange(`${row.index}.2`, activeTab, setActiveTab);
                                }}
                                type="button">
                                <i className="far fa-user"></i>
                                Reservation
                            </NavLink>
                        </NavItem>
                    )}
                    {subscriptionPermission && (
                        <NavItem>
                            <NavLink to="#"
                                className={` fw-semibold fs-5 ${classnames({ active: activeTab === `${row.index}.3` })}`}
                                onClick={() => {
                                    tabChange(reservationPermission ? `${row.index}.3` : `${row.index}.2`, activeTab, setActiveTab);
                                }}
                                type="button">
                                <i className="far fa-user"></i>
                                Subscription
                            </NavLink>
                        </NavItem>
                    )}
                    <NavItem>
                        <NavLink to="#" className={`fw-semibold fs-5 ${classnames({ active: activeTab === `${row.index}.4` })}`}
                            onClick={() => {
                                tabChange(subscriptionPermission ? `${row.index}.4` : reservationPermission ? `${row.index}.3` : `${row.index}.2`, activeTab, setActiveTab);
                            }} type="button">
                            Attributes
                        </NavLink>
                    </NavItem>
                </Nav>
            </CardHeader>
            <CardBody className="p-4">
                <TabContent activeTab={activeTab}>
                    <TabPane tabId={`${row.index}.1`} className="scrollable-view">
                        <Accordion open={openAccordion} toggle={toggleAccordion}>
                            <AccordionItem>
                                <AccordionHeader targetId='1'>
                                    <h4 className="card-title mb-0 flex-grow-1">Non - Currency</h4>
                                </AccordionHeader>
                                <AccordionBody accordionId='1'>
                                    {!noCurrencyArray || noCurrencyArray.length === 0 ? (
                                        <h5 className='text-center'>No Records Found</h5>
                                    ) : (
                                        noCurrencyArray.map((value, index) => (
                                            <Card className='p-2' key={index}>
                                                <div>
                                                    <div className='d-flex justify-content-between'>
                                                        <LabelText classLabelName={"fw-bold fs-4"} displayText={value?.resourceName} />
                                                        <LabelText
                                                            classLabelName={"fw-bold"}
                                                            displayText={`${typeof value?.remainingBalance === 'number'
                                                                    ? value?.remainingBalance === 1.7976931348623157e+308
                                                                        ? ''
                                                                        : value?.remainingBalance > 100000
                                                                            ? (value?.remainingBalance / 100000).toFixed(2) + ' lakh'
                                                                            : value?.remainingBalance.toLocaleString()
                                                                    : ''
                                                                } ${value?.remainingBalance === 1.7976931348623157e+308 ? '' : 'Credits Left'}`}
                                                        />
                                                    </div>
                                                    <div className='d-flex justify-content-between'>
                                                        <div>
                                                            {value?.consumedBalance ? (
                                                                <LabelText
                                                                    displayText={`${value?.consumedBalance === 1.7976931348623157e+308
                                                                            ? ''
                                                                            : value?.consumedBalance !== undefined && `${value?.consumedBalance} Credits Consumed`
                                                                        }`}
                                                                />
                                                            ) : (
                                                                <LabelText displayText={"0 Credits Consumed"} />
                                                            )}
                                                        </div>
                                                        <div>
                                                            <LabelText
                                                                displayText={`${value?.originalBalance === 1.7976931348623157e+308
                                                                        ? "Unlimited Data"
                                                                        : value?.originalBalance > 10000
                                                                            ? (value?.originalBalance / 100000).toFixed(2) + ' lakh'
                                                                            : value?.originalBalance.toLocaleString()
                                                                    } Balance`}
                                                            />
                                                        </div>
                                                    </div>
                                                    <Progress max={value?.originalBalance} value={value?.consumedBalance ? value?.consumedBalance : 0}></Progress>
                                                    <div className='d-flex justify-content-end mt-2'>
                                                        <LabelText displayText={`${formatDate(value?.validFrom)} - ${formatDate(value?.validTo)}`} />
                                                    </div>
                                                </div>
                                            </Card>
                                        ))
                                    )}
                                </AccordionBody>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionHeader targetId='2'>
                                    <h4 className="card-title mb-0 flex-grow-1">Currency</h4>
                                </AccordionHeader>
                                <AccordionBody accordionId='2'>
                                    <Card className='p-2'>
                                        {currencyArray.length === 0 ? <h5 className='text-center'>No Records Found</h5> :
                                            currencyArray.map((value, index) => (
                                                <div key={index}>
                                                    <div className='d-flex justify-content-between'>
                                                        <LabelText classLabelName={"fw-bold fs-4"} displayText={value.resourceName} />
                                                        <div>
                                                            <LabelText classLabelName={"fw-bold"} displayText={`${value.currencySymbol} `} />
                                                            <LabelText classLabelName={"ms-1"} displayText={`${value.originalBalance}`} />
                                                        </div>
                                                    </div>
                                                    {value.originalBalance !== 1.7976931348623157e+308 && (
                                                        value.type === "Postpaid" ?
                                                            <Progress max={value.originalBalance} value={value.consumedBalance ? value.consumedBalance : 0}></Progress>
                                                            : <Progress max={value.remainingBalance} value={value.originalBalance}></Progress>
                                                    )}
                                                    <div className='d-flex justify-content-between'>
                                                        <div className="mt-2">
                                                            {value.consumedBalance && (
                                                                <LabelText displayText={`${value.consumedBalance === 1.7976931348623157e+308
                                                                    ? ''
                                                                    : value.consumedBalance !== undefined
                                                                    && `${value.consumedBalance} Credits Consumed`}`} />
                                                            )}
                                                        </div>
                                                        {fetchCustomerSummaryData.resources.filter((otherItem) =>
                                                            otherItem.subscriptionId === value.subscriptionId &&
                                                            otherItem.offerCode === value.offerCode).length !== 1 ? (
                                                            <LabelText classLabelName={"bg-success p-1 text-white rounded-2 mt-2"} displayText={"Paid"} />
                                                        ) : null}
                                                    </div>
                                                    <div>
                                                        <LabelText displayText={`Remaining Balance : ${value.remainingBalance}`} />
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </Card>
                                </AccordionBody>
                            </AccordionItem>
                        </Accordion>
                    </TabPane>
                    {reservationPermission && (
                        <TabPane tabId={`${row.index}.2`} className="scrollable-view">
                            {!reservationDataArray.length ? (
                                <div className="d-flex justify-content-center align-items-center default-height">
                                    <div><h4>No Reservations</h4></div>
                                </div>
                            ) : (
                                <div>
                                    <div className="live-preview">
                                        <div className="table-responsive">
                                            <div className="scrollable-view">
                                                <Table className="table-borderless align-middle mb-0" striped>
                                                    <thead>
                                                        <tr>
                                                            <th scope="col">Station Name - Address</th>
                                                            <th scope="col">Duration - Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {reservationDataArray.map((value, index) => (
                                                            value.name !== undefined ? (
                                                                <tr key={index}>
                                                                    <td>
                                                                        <LabelText displayText={`${value.name}`} /><br />
                                                                        <LabelText displayText={value.address} />
                                                                    </td>
                                                                    <td>
                                                                        <LabelText displayText={`${duration(value.startDate)} - ${duration(value.endDate)}`} /><br />
                                                                        <LabelText displayText={formatDate(value.endDate)} />
                                                                    </td>
                                                                </tr>
                                                            ) : null
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-none code-view">
                                        <pre className="language-markup default-height">
                                            <code>
                                                <TablesWithoutBorders />
                                            </code>
                                        </pre>
                                    </div>
                                </div>
                            )}
                        </TabPane>
                    )}
                    {subscriptionPermission && (
                        <TabPane tabId={reservationPermission ? `${row.index}.3` : `${row.index}.2`} className="scrollable-view">
                            {!subscriptionsArray || !subscriptionsArray.length || !subscriptionsArray.some(subscription => subscription?.subscribedOffers?.length > 0) ? (
                                <h4 className="d-flex justify-content-center align-items-center default-height">No Subscriptions</h4>
                            ) : (
                                subscriptionsArray.map((value, index) => {
                                    const offer = value?.subscribedOffers?.[0];
                                    if (!offer) return null;
                                    const offerName = offer?.offerName;
                                    const validTo = offer?.validTo;
                                    const status = offer?.status;
                                    const isActive = status === "CANCEL" ? "IN-ACTIVE" : "ACTIVE";
                                    const statusClass = status === "CANCEL" ? "bg-danger" : "bg-success";
                                    return (
                                        <Card className='p-2 d-flex' key={index}>
                                            <div className='flex-column'>
                                                <LabelText classLabelName={"fw-bold fs-5"} displayText={value?.subscriptionId} />
                                                <div className='flex-row position-relative'>
                                                    {offerName && <LabelText classLabelName={"me-2"} displayText={offerName} />}
                                                    <LabelText displayText={"-"} />
                                                    {validTo && (
                                                        <LabelText
                                                            classLabelName={"ms-2"}
                                                            displayText={` ${convertNumberOfDaysToMonthYear(getDayDiff(new Date(current), new Date(validTo)))} left`}
                                                        />
                                                    )}
                                                    <div className='position-absolute top-0 end-0'>
                                                        <Label className={`${statusClass} py-1 text-white text-center rounded-2 status`}>{isActive}</Label>
                                                    </div>
                                                </div>
                                                {validTo && <LabelText displayText={formatDate(validTo)} />}
                                            </div>
                                        </Card>
                                    );
                                })
                            )}
                        </TabPane>
                    )}
                    <TabPane tabId={subscriptionPermission ? `${row.index}.4` : reservationPermission ? `${row.index}.3` : `${row.index}.2`} className="scrollable-view">
                        {!customerAttributeData || !customerAttributeData.length ? (
                            <h4 className="d-flex justify-content-center align-items-center default-height">No Attributes</h4>
                        ) : (
                            <MaterialReactTable
                                data={customerAttributeData}
                                columns={attributeColumn}
                                enableDensityToggle={false}
                                icons={{
                                    DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                                }}
                                muiTableHeadCellProps={{
                                    sx: {
                                        fontFamily: "Inter,sans-serif"
                                    }
                                }}
                                muiTableBodyCellProps={{
                                    sx: {
                                        fontFamily: "Inter,sans-serif"
                                    }
                                }}
                            />
                        )}
                    </TabPane>
                </TabContent>
            </CardBody>
        </Card>
    );
};
