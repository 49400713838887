import { DOCUMENT_UPLOAD_AND_EDIT_AND_DELETE_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { fetchDocumentUploadSuccess, fetchDocumentUploadFailure } from "./reducer";
import { setDocumentSuccessMessage, setSuccessMessage } from "../../toastMessage/action";
import { toast } from "react-toastify";
import { fetchDocumentsForCustomer } from "../documentlist/thunk";

export const uploadDocument = (jsonObj, setLoading, customerId, role, navigate) => async (dispatch) => {
    setLoading(true)
    await determineBaseUrlAndPerformApiCall(`${DOCUMENT_UPLOAD_AND_EDIT_AND_DELETE_API}`, 'POST', jsonObj).then(async (response) => {
        if (!response.error) {
            setLoading(false)
            dispatch(fetchDocumentUploadSuccess(response))
            dispatch(fetchDocumentsForCustomer(setLoading, customerId))
            if (role === "User") {
                navigate("/user")
                dispatch(setSuccessMessage(true))
            }
            else if (role === "Operator") {
                navigate('/operator-listing');
                // dispatch(setSuccessMessage(true))
            }else if( role === "Customer"){
                navigate('/customer');
                dispatch(setSuccessMessage(true))
            }
            else {
                toast.success(
                    'Document Uploaded Successfully'
                )
                dispatch(setDocumentSuccessMessage(true))
            }
        }
        if (response.error) {
            setLoading(false)
            toast.error(
                `${response.error.message}`
            )
            dispatch(fetchDocumentUploadFailure(response.error.message))
        }
        setLoading(false)
    }).catch((error) => { })
}