import React, { useState, useMemo, useEffect } from "react";
import { Card, CardHeader, CardBody, Row, Col, Label, FormGroup, Nav, NavItem, NavLink, Table } from "reactstrap";
import { LabelText } from "../Utils/Input";
import PieChart from "./constants/PieChart";
import { CustomerAddress, CustomerViewDetails } from "./CustomerViewDetail";
import { Carousel, CarouselItem, CarouselControl, CarouselIndicators } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import SelectField from "../Utils/SelectField";
import classnames from 'classnames';
import AggregateResourceCarousel from "./AggregateResource";
import { goToIndex, next, previous } from "./constants/corouselNavigation";
import { viewDoc } from "../../slices/customer/documentview/thunk";
import logoLarge from '../../assets/images/users/user-dummy-img.jpg'
import noData from '../../assets/images/aggregate/noData.png'

export const CustomerProfile = ({ customerPermission, customerData, fetchCustomerSummaryData, formatDate, resourceData }) => {
    const [selectedSubscriptionId, setSelectedSubscriptionId] = useState("");
    const [activeIndex, setActiveIndex] = useState(0);
    const [animating, setAnimating] = useState(false);
    const [activeTabForAddOn, setActiveTabForAddOn] = useState("1");
    const [addOnLength, setAddOnLength] = useState(0);
    const [baseOfferLength, setBaseOfferLength] = useState(0);
    const [profileData,setProfileData]=useState(false);
    const [profileImage, setProfileImage]=useState(logoLarge);
    const [loading,setLoading]=useState(false);
    const roleName = localStorage.getItem('roleName')
    const aggregateResources = fetchCustomerSummaryData?.resources?.filter(resource => resource.isAggregate);
    const dispatch = useDispatch()
    const tabChange = (tab) => {
        if (activeTabForAddOn !== tab) setActiveTabForAddOn(tab);
    };
    const groupedResources = useMemo(() => {
        return fetchCustomerSummaryData?.resources?.reduce((acc, resource) => {
            const key = `${resource.subscriptionId}-${resource.offerCode}`;
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(resource);
            return acc;
        }, {});
    }, [fetchCustomerSummaryData]);

    const handleSubscriptionChange = (selectedOption) => {
        setSelectedSubscriptionId(selectedOption ? selectedOption.value : "");
        setActiveIndex(0);
    };
    const uniqueSubscriptionIds = useMemo(() => {
        return [...new Set(
            fetchCustomerSummaryData?.resources?.filter(resource => !resource.isAggregate && resource.subscriptionId)?.map(resource => resource.subscriptionId)
        )];
    }, [fetchCustomerSummaryData]);
    useEffect(() => {
        const addOns = fetchCustomerSummaryData?.resources?.filter(resource => resource.isAddOn) || [];
        const baseOffers = fetchCustomerSummaryData?.resources?.filter(resource => !resource.isAddOn) || [];
        setAddOnLength(addOns.length || 0);
        setBaseOfferLength(baseOffers.length || 0);
    }, [fetchCustomerSummaryData?.resources]);
    useEffect(() => {
        if (uniqueSubscriptionIds.length > 0 && !selectedSubscriptionId) {
            setSelectedSubscriptionId(uniqueSubscriptionIds[0]);
        }else {
            setSelectedSubscriptionId("");
        }
    }, [uniqueSubscriptionIds,customerData]);
    useEffect(() => {
        if (customerData && customerData?.customerInfo?.userName) {
            let partnerOrCustomer = "Customer"
            dispatch(viewDoc(setLoading, customerData?.customerInfo?.customerId, "logo", partnerOrCustomer,setProfileData))
        }
    }, [dispatch, customerData]);
    let ViewDocument = useSelector(state => state.ViewDocument.documentView)
    let base64ImageData = ViewDocument?.filePath
    useEffect(() => {
        if (base64ImageData) {
            setProfileImage(base64ImageData)
        }else{
            setProfileImage(logoLarge)
        }
    }, [base64ImageData])
    const renderGrantsCarousel = (resources) => {
        const slides = resources.map((resource) => (
            <CarouselItem
                onExiting={() => setAnimating(true)}
                onExited={() => setAnimating(false)}
                key={resource.resourceName}
            >
                <Card className="border border-2 customer-view-screen">
                    <CardHeader className="d-flex align-items-center customer-view-screen">
                        <Label className="fs-5 mt-2">Grant Bucket</Label>
                    </CardHeader>
                    <CardBody className="px-5">
                        <div className="d-flex flex-column align-items-center flex-md-row">
                            <PieChart
                                remainingBalance={resource.remainingBalance || 0}
                                originalBalance={resource.originalBalance}
                                width={"100%"}
                                height={200}
                            />
                            <div>
                                <p className="fw-bolder fs-5">{resource.resourceName}</p>
                                <p>
                                    <b>{resource.remainingBalance||0}</b> left of <b>{resource.originalBalance}</b>
                                </p>
                                <p className="d-flex justify-content-start">
                                    <LabelText displayText={`${formatDate(resource.validFrom)} - ${formatDate(resource.validTo)}`} />
                                </p>
                            </div>
                        </div>
                    </CardBody>
                </Card>
            </CarouselItem>
        ));
        return (
            <Carousel
                activeIndex={activeIndex}
                next={() => next(resources, activeIndex, setActiveIndex, animating)}
                previous={() => previous(resources, activeIndex, setActiveIndex, animating)}
                interval={false}
            >
                <CarouselIndicators items={resources} activeIndex={activeIndex} onClickHandler={(newIndex) => goToIndex(newIndex, animating, setActiveIndex)} />
                {slides}
                <CarouselControl direction="prev" directionText="Previous" onClickHandler={() => previous(resources, activeIndex, setActiveIndex, animating)} />
                <CarouselControl direction="next" directionText="Next" onClickHandler={() => next(resources, activeIndex, setActiveIndex, animating)} />
            </Carousel>
        );
    };
    return (
        <Row lg={12}>
        {roleName !== 'Customer' && (<Col lg={4}>
        <div className='listingjs-table' id='customerList'>
            <Card className="border aggregate-resource-view mb-1">
                <CardBody>
                    <div className='live-preview'>
                        <div className="text-center position-relative p-3">
                            <div className="position-relative p-3 mx-auto mb-1">
                                <img
                                    src={profileImage}
                                    className="rounded-circle outline-dark avatar-xl img-thumbnail user-profile-image mb-4"
                                    alt="user-profile"
                                />
                                <div className="fs-5">
                                    {customerData?.customerInfo?.userName || ''}
                                </div>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>
            <Card className={`border pt-0 ${(!aggregateResources?.length && roleName !== "Customer" ) && "mt-4"}`}>
                <>
                <header className="p-1 ms-3 mt-0 pb-0 mb-0">
                    <h2 className="fs-5 mt-4 fw-medium balance-heading-color">Aggregate View</h2>
                </header>
                {aggregateResources?.length > 0 && roleName !== 'Customer'?
                <div className=" mb-2">
                    <AggregateResourceCarousel resources={aggregateResources} />
                </div>
                :
                <div className="d-flex justify-content-center align-items-center mt-0 pt-0 mb-4">
                    <img
                        src={noData}
                        alt="No Data available"
                        className='img-fluid w-50 user-profile-image mt-0 pt-0 mb-1'
                        style={{height:"175px"}}
                    />
                </div>
                }
                </>
            </Card>
        </Col>)}
        {roleName !== 'Customer' && (
            <Col lg={8}>
                <CustomerViewDetails customerData={customerData} customerPermission={customerPermission} aggregateResources={aggregateResources} />
                <CustomerAddress customerData={customerData} aggregateResources={aggregateResources} />
            </Col>
        )}
            <Col lg={12}>
                <div className="border-bottom mt-n4" />
                    <Card className="border border-2">
                        <CardHeader className="d-flex align-items-center">
                            <Label className="fs-5 mt-2 balance-heading-color">Subscriptions</Label>
                        </CardHeader>
                        {groupedResources ? (
                        <>
                        <Row>
                            <Col xxl={4} lg={4} md={4} xs={11} className="ms-3 mt-2 ">
                                <FormGroup>
                                    <Label for="subscriptionSelect" className="fw-bold">Subscription ID</Label>
                                    <SelectField
                                        id="subscriptionSelect"
                                        value={selectedSubscriptionId ? { value: selectedSubscriptionId, label: selectedSubscriptionId } : null}
                                        placeholder="Select Subscription ID"
                                        handleChange={handleSubscriptionChange}
                                        options={uniqueSubscriptionIds.map(id => ({ value: id, label: id }))}

                                        isMulti={false}
                                    />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Nav className="nav-tabs-custom rounded card-header-tabs border-top border-bottom mt-2  mb-3" role="tablist">
                            <div className="ms-3 d-flex justify-content-between">
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTabForAddOn === "1" })}
                                        onClick={() => tabChange("1")}
                                    >
                                        Base Offer
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTabForAddOn === "2" })}
                                        onClick={() => tabChange("2")}
                                    >
                                        Add-On Offer
                                    </NavLink>
                                </NavItem></div>
                        </Nav>
                        {activeTabForAddOn === "2" && addOnLength <= 0 && (
                            <CardBody>
                                <p className="d-flex justify-content-center fw-bold fs-5 balance-heading-color">
                                    No Add-Ons purchased for this subscription
                                </p>
                            </CardBody>
                        )}
                        {selectedSubscriptionId &&
                            Object.keys(groupedResources)?.filter(key => key.startsWith(selectedSubscriptionId)).map((key, index) => {
                                const resources = groupedResources[key];
                                const filteredResources = resources?.filter(resource =>
                                    (activeTabForAddOn === "1" && !resource.isAddOn) ||
                                    (activeTabForAddOn === "2" && resource.isAddOn)
                                );
                                return (
                                    <CardBody key={index} className="card-body">
                                        {filteredResources.length > 0 ? (
                                            <Row className="gy-4">
                                                <Col lg={6}>
                                                    {filteredResources.length > 1 ? renderGrantsCarousel(filteredResources) : (
                                                        <Card className="border border-2 customer-view-screen">
                                                            <CardHeader className="d-flex align-items-center customer-view-screen">
                                                                <Label className="fs-5 mt-2">Grant</Label>
                                                            </CardHeader>
                                                            <CardBody className="px-5">
                                                                <div className="d-flex flex-column align-items-center flex-md-row">
                                                                    <PieChart
                                                                        remainingBalance={filteredResources[0].remainingBalance}
                                                                        originalBalance={filteredResources[0].originalBalance}
                                                                        width={"100%"}
                                                                        height={200}
                                                                    />
                                                                    <div>
                                                                        <p className="fw-bolder fs-5">{filteredResources[0].resourceName}</p>
                                                                        <p>
                                                                            <b>{filteredResources[0].remainingBalance}</b> left of <b>{filteredResources[0].originalBalance}</b>
                                                                        </p>
                                                                        <p className="d-flex justify-content-start">
                                                                            <LabelText displayText={`${formatDate(filteredResources[0].validFrom)} - ${formatDate(filteredResources[0].validTo)}`} />
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    )}
                                                </Col>
                                                <Col lg={6}>
                                                    {filteredResources && (
                                                        <Card className="border border-2" style={{ height: "288px" }}>
                                                            <CardHeader className="d-flex align-items-center">
                                                                <h5 className="fs-5 mb-0">Usage</h5>
                                                            </CardHeader>
                                                            <div className="table-responsive">
                                                                <Table className="table table-borderless table-striped">
                                                                    <thead>
                                                                        <tr>
                                                                            <th className="p-3" scope="col">Name</th>
                                                                            <th className="p-3" scope="col">Value</th>
                                                                            <th className="p-3" scope="col">Units</th>
                                                                            <th className="p-3" scope="col">Time Period</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {resources?.map((resource, idx) => (
                                                                            <tr key={idx}>
                                                                                <td className="p-3">{resource?.resourceName}</td>
                                                                                <td className="p-3 ">{(resource?.consumedBalance)}</td>
                                                                                <td className="p-3">{resource?.uom}</td>
                                                                                <td>{`${formatDate(resource?.validFrom)} - ${formatDate(resource?.validTo)}`}</td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </Card>
                                                    )}
                                                </Col>

                                            </Row>
                                        ) : null}
                                    </CardBody>
                                );
                            })
                        }
                        </>
                    ):
                    <div className="d-flex justify-content-center align-items-center text-muted p-5 fst-italic">
                        No active subscriptions yet
                    </div>
                    }
                </Card>
            </Col>
        </Row>
    )
};

export default CustomerProfile;
