import { useEffect, useMemo, useState } from "react";
import React from "react";
import { MaterialReactTable } from "material-react-table";
import { MenuItem } from '@mui/material';
import { useDispatch, useSelector } from "react-redux";
import { cancelSubscription } from "../../slices/customer/cancelsubscription/thunk";
import { ToastContainer } from "react-toastify";
import { SubscriptionsFormatData, convertNumberOfDaysToMonthYear, getDayDiff } from "../Customers/constants/DateFormat";
import { subscriptionColumns } from "../Customers/constants/CustomerTableColumns";
import { subscriptionsJsonObj } from "../Customers/constants/CustomerJsonObj";
import { statusData, termValidityData, showPopUp, customerIdData, AddOnState, subscriptionListData, subscriptionsaArrayData } from "../Customers/constants/constantFunctionsView";
import { Loader } from "../Utils/Loader";
import CommonModal from "../Utils/CommonModal";
import { unsubscribeOffer } from "../Customers/constants/DocumentTableFunctions";
import { useNavigate } from "react-router-dom";
import { Card, CardBody, CardHeader, Container, Row, Col } from "reactstrap";
import BreadCrumb from "../Common/BreadCrumb";
import { PAGE_TITLES } from "../Common/constants";
import { fetchCustomer } from "../../slices/customer/fetchcustomer/thunk";
import { initialFormValues } from "../Customers/constants/constantValues";
import { createTheme, ThemeProvider, useTheme } from '@mui/material';
import { useColumnSettings } from "../Utils/useColumnSettings";
import { getView } from "../../slices/View/thunk";

export default function SubscriptionListSelfcare() {
    const [subscriptionId, setSubscriptionId] = useState('');
    const [messageForPopUp, setMessageForPopUp] = useState('');
    const [offer, setOffer] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openPopUp, setOpenPopUp] = useState(false);

    const customerId = localStorage.getItem('customerId');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const validFrom = SubscriptionsFormatData(offer, [], "validFrom");
    const validTo = SubscriptionsFormatData(offer, [], "validTo");
    const status = statusData(offer, []);
    const addOnOffer = AddOnState(offer, []);
    const termValidity = termValidityData(offer, []);
    const columns = subscriptionColumns(termValidity, validFrom, validTo, status, addOnOffer);

    const permissions = useSelector((state) => state.permissions.permissions);
    const customerPermission = permissions.find((resource) => resource.resourceName === 'customer');
    const customerData = useSelector((state) => state.FetchCustomer.fetchCustomer);
    const subscriptionPermission = permissions.find((resource) => resource.resourceName === 'subscription');

    const layoutModeType = useSelector((state) => state.Layout.layoutModeType);

    useEffect(() => {
        if (customerPermission) {
            dispatch(fetchCustomer(setLoading, customerId, initialFormValues, customerPermission));
            dispatch(getView(setLoading))
        }
    }, []);

    useEffect(() => {
        const subscriptionList = subscriptionListData(customerData, []);
        const subscriptionsArray = subscriptionsaArrayData([], subscriptionList);
        setOffer(subscriptionsArray);
    }, [customerData]);

    const globalTheme = useTheme();

    const tableTheme = useMemo(() => {
        const darkMode = layoutModeType === 'dark';

        return createTheme({
            palette: {
                mode: darkMode ? 'dark' : globalTheme.palette.mode,
                primary: darkMode ? { main: '#ffffff' } : globalTheme.palette.primary,
                background: {
                    default: darkMode ? '#212529' : globalTheme.palette.background.default,
                },
                text: {
                    primary: darkMode ? '#ffffff' : globalTheme.palette.text.primary,
                },
            },
            typography: {
                button: {
                    textTransform: 'none',
                    fontSize: '1.2rem',
                },
            },
            components: {
                MuiTableCell: {
                    styleOverrides: {
                        root: {
                            backgroundColor: darkMode ? '#212529' : undefined,
                            color: darkMode ? '#ffffff' : undefined,
                        },
                    },
                },
                MuiTableHeadCell: {
                    styleOverrides: {
                        root: {
                            backgroundColor: darkMode ? '#343a40' : undefined,
                            color: darkMode ? '#ffffff' : undefined,
                        },
                    },
                },
            },
        });
    }, [globalTheme, layoutModeType]);

    const {
        settingsModalOpen,
        viewColumns,
        toggleSettingsModal,
        SettingsSidebar
    } = useColumnSettings('subscriptions', columns);

    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                <Container fluid>
                    <BreadCrumb title={PAGE_TITLES.SUBSCRIPTIONS} pageTitle={PAGE_TITLES.SUBSCRIPTIONS} />
                    <ToastContainer position='top-center' />
                    <Row>
                        <Col lg={settingsModalOpen ? 10 : 12}>
                            <div className="listingjs-table" id="customerList">
                                <Card>
                                    <CardBody>
                                        <Row className="mb-3 mt-1 table-responsive">
                                            {loading && <Loader />}
                                            {offer && (
                                                <ThemeProvider theme={tableTheme}>
                                                    <MaterialReactTable
                                                        columns={columns.filter((column) => viewColumns?.includes(column.accessorKey || column.header))}
                                                        data={offer}
                                                        enableDensityToggle={false}
                                                        enableColumnOrdering
                                                        manualFiltering
                                                        enableColumnDragging
                                                        enableRowActions={subscriptionPermission && subscriptionPermission.permissionList.includes("unsubscribe")}
                                                        icons={{
                                                            DragHandleIcon: () => <i className="ri-drag-move-fill" />,
                                                            // SearchIcon: () => <i className="ri-search-line" />,
                                                            ClearAllIcon: () => <i className="ri-close-line" />,
                                                        }}
                                                        renderTopToolbarCustomActions={({ }) => (
                                                            <div className="d-flex flex-column flex-md-row align-items-md-center justify-content-between w-100">
                                                                <div className="d-flex flex-column flex-md-row">
                                                                </div>
                                                                    <div className="d-flex align-items-center">
                                                                        <div color="secondary" className="btn-icon me-2 cursor-img" onClick={toggleSettingsModal}>
                                                                            <i className="ri-settings-2-line fs-4"></i>
                                                                        </div>
                                                                    </div>
                                                            </div>
                                                        )}
                                                        // muiTableHeadCellProps={{
                                                        //     sx: {
                                                        //         fontFamily: 'Inter, sans-serif',
                                                        //         backgroundColor: layoutModeType === 'dark' ? '#343a40' : '#f8f9fa',
                                                        //         color: layoutModeType === 'dark' ? 'white' : '#212529',
                                                        //     },
                                                        // }}
                                                        muiTableBodyRowProps={{
                                                            sx: {
                                                                backgroundColor: layoutModeType === 'dark' ? '#212529' : '#f8f9fa',
                                                                '&:hover': {
                                                                    backgroundColor: layoutModeType === 'dark' ? '#343a40' : '#e9ecef',
                                                                },
                                                            },
                                                        }}
                                                        muiTableBodyCellProps={{
                                                            sx: {
                                                                backgroundColor: layoutModeType === 'dark' ? '#212529' : '#ffffff',
                                                                color: layoutModeType === 'dark' ? 'white' : '#212529',
                                                                '&:hover': {
                                                                    backgroundColor: layoutModeType === 'dark' ? '#343a40' : '#e9ecef',
                                                                },
                                                            },
                                                        }}
                                                        renderRowActionMenuItems={({ closeMenu, row }) => [
                                                            subscriptionPermission && subscriptionPermission.permissionList.includes("unsubscribe") ? (
                                                                <MenuItem
                                                                    disabled={row.original.status === "CANCEL"}
                                                                    key="view"
                                                                    onClick={() => {
                                                                        setMessageForPopUp(`Are you sure you want to cancel this subscription "${row.original.offerName}"?`);
                                                                        showPopUp(row, setSubscriptionId, setOpenPopUp, openPopUp);
                                                                        closeMenu();
                                                                    }}>
                                                                    <i className='ri ri-delete-bin-5-line me-2 text-danger' />
                                                                    Cancel Subscription
                                                                </MenuItem>
                                                            ) : null,
                                                        ]}
                                                    />
                                                </ThemeProvider>
                                            )}
                                            {openPopUp && (
                                                <CommonModal
                                                    open={openPopUp}
                                                    toggle={() => setOpenPopUp(!openPopUp)}
                                                    messageForPopUp={messageForPopUp}
                                                    buttonText={"Yes, Cancel It"}
                                                    modalAction={() => {
                                                        unsubscribeOffer(customerData, customerId, subscriptionId, setLoading, dispatch);
                                                        setOpenPopUp(!openPopUp);
                                                    }}
                                                />
                                            )}
                                        </Row>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                        {SettingsSidebar()}
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}
