import { toast } from "react-toastify";

import { CANCEL_ORDER_API } from "../../constants/constants";
import { determineBaseUrlAndPerformApiCall } from "../../interceptor/interceptor";
import { fetchCancelOrderFailure, fetchCancelOrderSuccess } from "../cancelorder/reducer";

export const cancelOrder = (jsonObj) => async (dispatch) => {
    try{
        const response = await determineBaseUrlAndPerformApiCall(CANCEL_ORDER_API, 'POST', jsonObj);
        if (!response.error) {
            dispatch(fetchCancelOrderSuccess(response));
        } else {
            dispatch(fetchCancelOrderSuccess(response.error.message));
            toast.error(`Failed to create order: ${response.error.message}`);
        }
    } catch (error) {
        dispatch(fetchCancelOrderFailure("An error occurred while creating the order."));
        toast.error("An error occurred while creating the order.");
    }
}