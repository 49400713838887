import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import { Col, Container, Form, Row, Button, Progress, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import BreadCrumb from "../Common/BreadCrumb";
import { ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import CommonButton from "../Utils/CommonButton";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../Utils/Loader";
import classnames from "classnames";
import { validationSchema } from "./Constant/validationSchema";
import { customerIdData } from "../Customers/constants/constantFunctionsView";
import OperatorDetails from "./OperatorDetails";
import { fetchCountries } from "../../slices/customer/countries/thunk";
import { countryOptionss } from "./Constant/Options";
import { fetchStates } from "../../slices/customer/states/thunk";
import { fetchCities } from "../../slices/customer/cities/thunk";
import { OperatorOptionsFields, OperatorStatusOptions } from "./Constant/Options";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { createOperator } from "../../slices/OperatorManagement/CreateOperator/thunk";
import { editOperator } from "../../slices/OperatorManagement/EditOperator/thunk";
import { fetchOperatorbyId } from "../../slices/OperatorManagement/GetOperatorbyID/thunk";
import { viewDoc } from "../../slices/customer/documentview/thunk";
import moment from "moment";
import ConfigurationForOperator from "./configurationOperator";
import CommonModal from "../Utils/CommonModal";
import { toggleModal } from "../Customers/constants/TelcoSubmitFunctions";
import { fetchOperatorSpecificConfigurations } from "../../slices/OperatorManagement/GetConfigurationByOperatorId/thunk";

export default function CreateEditOperators() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState();
    const [stateData, setStatedata] = useState();
    const [cityData, setCityData] = useState()
    const [selectedCountry, setSelectedCountry] = useState()
    const [selectedState, setSelectedState] = useState()
    const [editData, setEditData] = useState(null);
    const isEdit = location.pathname.includes('edit-operator');
    const [logoFile, setLogoFile] = useState(null);
    const [imageOn, setImageOn] = useState(false);
    const fileInputRef = useRef(null);
    const [profileData, setProfileData] = useState(false)
    const [fileSelected, setFileSelected] = useState(false);
    const [callState, setCallState] = useState(false)
    const [callCity, setCallCity] = useState(false)
    const [progressbarvalue, setprogressbarvalue] = useState(0);
    const [activeTab, setactiveTab] = useState(1);
    const [passedSteps, setPassedSteps] = useState([1]);
    const [finalValues, setFinalValues] = useState({});
    const [confirmed, setConfirmed] = useState()
    const [modal, setModal] = useState(false);

    const operatorId = customerIdData();
    function toggleTab(tab, value) {
        if (activeTab !== tab) {
            var modifiedSteps = [...passedSteps, tab];

            if (tab >= 1 && tab <= 4) {
                setactiveTab(tab);
                setPassedSteps(modifiedSteps);
            }
        }
        setprogressbarvalue(value);
    }

    useEffect(() => {
        dispatch(fetchCountries(setLoading, setCallState))
    }, [dispatch]);
    const countries = useSelector(state => state.Countries.countries)
    const countryOptions = countryOptionss(countries?.countries)

    const operatorOptions = [
        {
            name: "Domain",
            value: "Operator",
            options: OperatorOptionsFields
        },
        {
            name: "country",
            value: "Operator",
            options: countryOptions
        },
        {
            name: "Status",
            value: "Operator",
            options: OperatorStatusOptions
        }
    ]
    useEffect(() => {
        if (callState) {
            dispatch(fetchStates(selectedCountry, setCallCity, "isOperator", setStatedata))
        }
    }, [dispatch, isEdit, selectedCountry]);

    useEffect(() => {
        if (callCity) {
            dispatch(fetchCities(selectedCountry, selectedState, "isOperator", setCityData))
        }
    }, [dispatch, selectedCountry, selectedState]);

    const initialValues = {
        businessName: "",
        Domain: "",
        state: "",
        city: "",
        zipCode: "",
        endDate: null,
        Address: "",
        Address2: "",
        country: "",
        firstname: "",
        lastname: "",
        username: "",
        phone: "",
        email: "",
        Status: "",
        // attributeList: []
    };

    useEffect(() => {
        if (isEdit) {
            dispatch(fetchOperatorbyId(setLoading, operatorId, initialValues, operatorOptions, OperatorStatusOptions, setEditData));
            dispatch(fetchOperatorSpecificConfigurations(setLoading,operatorId))
        }
    }, [dispatch, setLoading]);
    const operatorData = useSelector((state) => state?.GetOperator?.GetOperatorId ?? null);
    const configData = useSelector((state) => state.OperatorSpecificConfig.OperatorSpecificConfiguration)
    useEffect(() => {
        if (isEdit && editData && editData?.tradingName) {
            let partnerOrCustomer = "Operator"
            dispatch(viewDoc(setLoading, operatorId, "logo", partnerOrCustomer, setProfileData));
        }
    }, [dispatch, isEdit, editData]);

    let profile = useSelector((state) => state.ViewDocument.documentView)
    const [update, setUpdate] = useState(!!profile);
    let base64ImageData = []
    base64ImageData = profile?.filePath
    let documentName = profile?.documentName
    useEffect(() => {
        if (isEdit && base64ImageData) {
            setLogoFile(base64ImageData);
        }
        if (!profileData && isEdit) {
            setLogoFile(null)
        }
    }, [isEdit, base64ImageData, profileData]);

    const progressbar = progressbarvalue * 2;

    return (
        <React.Fragment>
            <div id='csms' className='page-content'>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={initialValues}
                    onSubmit={async (values) => { }}
                >
                    {({ handleChange, handleBlur, handleSubmit, errors, touched, values, setFieldValue, setFieldTouched }) => (
                        <Form className='needs-validation' onSubmit={handleSubmit}>
                            <Container fluid>
                                {loading && (<Loader />)}
                                <BreadCrumb title={isEdit ? 'Edit Operator' : 'Add operator'} pageTitle='' />
                                <ToastContainer position='top-center' />
                                <Row>
                                    <Col lg={12}>
                                        <div className='listingjs-table ' id='OperatorList'>
                                            <div className="progress-nav mb-4">
                                                <Progress
                                                    value={progressbar}
                                                    style={{ height: "2px", width: "50%", backgroundColor: "#ADB5BD" }}
                                                    className="csv-file-color"
                                                />
                                                <Nav
                                                    className="nav-pills progress-bar-tab custom-nav"
                                                    role="tablist"
                                                >
                                                    <NavItem>
                                                        <NavLink
                                                            href="#"
                                                            id="pills-gen-info-tab"
                                                            className={classnames(
                                                                {
                                                                    active: activeTab === 1,
                                                                    done: activeTab <= 4 && activeTab >= 0,
                                                                },
                                                                "rounded-pill"
                                                            )}
                                                            onClick={() => {
                                                                toggleTab(1, 0);
                                                            }}
                                                            tag="button"
                                                        >
                                                            1
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            href="#"
                                                            id="pills-gen-info-tab"
                                                            className={classnames(
                                                                {
                                                                    active: activeTab === 2,
                                                                    done: activeTab <= 4 && activeTab > 1,
                                                                },
                                                                "rounded-pill"
                                                            )}
                                                            disabled={(values?.firstname === "" && values?.lastname === "" && values?.email === "" && values?.username === "" && values?.phone === "")}
                                                            onClick={() => {
                                                                handleSubmit();
                                                                if (
                                                                    values?.firstname !== "" &&
                                                                    values?.lastname !== "" &&
                                                                    values?.email !== "" &&
                                                                    values?.username !== "" &&
                                                                    values?.phone !== ""
                                                                ) {
                                                                    toggleTab(2, 50);
                                                                }
                                                            }}
                                                            tag="button"
                                                        >
                                                            2
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                    </NavItem>
                                                </Nav>
                                            </div>
                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId={1}>
                                                    <OperatorDetails
                                                        isEdit={isEdit}
                                                        values={values}
                                                        errors={errors}
                                                        touched={touched}
                                                        setFieldTouched={setFieldTouched}
                                                        setFieldValue={setFieldValue}
                                                        handleBlur={handleBlur}
                                                        handleChange={handleChange}
                                                        countries={operatorOptions}
                                                        setSelectedCountry={setSelectedCountry}
                                                        setSelectedState={setSelectedState}
                                                        stateData={stateData}
                                                        cityData={cityData}
                                                        operatorData={operatorData}
                                                        logoFile={logoFile} operatorId={operatorId}
                                                        imageOn={imageOn} dispatch={dispatch} setLoading={setLoading}
                                                        profile={profile} documentName={documentName}
                                                        setLogoFile={setLogoFile} setUpdate={setUpdate}
                                                        setFileSelected={setFileSelected}
                                                        fileInputRef={fileInputRef}
                                                        setImageOn={setImageOn}
                                                    />
                                                    <div className='d-flex justify-content-end gap-2 '>
                                                        <CommonButton outline={true} className={"cancel-button-background"} color={"main-color"} type={"reset"} buttonAction={() => navigate('/operator-listing')} buttonText={"Cancel"} />
                                                        <Button color="main-color" onClick={() => {
                                                            handleSubmit();
                                                            if (
                                                                (values?.firstname !== "" &&
                                                                    values?.lastname !== "" &&
                                                                    values?.email !== "" &&
                                                                    values?.username !== "" &&
                                                                    values?.phone !== "")
                                                            ) {

                                                                toggleTab(2, 50);

                                                            }

                                                        }}>
                                                            Next
                                                        </Button>
                                                    </div>
                                                </TabPane>
                                                <TabPane tabId={2}>
                                                    <ConfigurationForOperator
                                                        setFinalValues={setFinalValues}
                                                        finalValues={finalValues}
                                                        values={values}
                                                        configData={configData}
                                                        isEdit={isEdit}
                                                    />
                                                    <Row lg={6} className='justify-content-end mb-4'>
                                                        <div className='live-preview'>
                                                            <div className='d-flex justify-content-end gap-2 '>
                                                                <Button className={"cancel-button-background"} outline="true" color="main-color" onClick={() => { toggleTab(1, 0) }}>Back </Button>
                                                                <Button color={"main-color"} onClick={() => {
                                                                    // if (
                                                                    //     values?.firstname !== "" && values?.lastname !== "" && values?.email !== "" && values?.username !== "" && values?.phone !== ""
                                                                    // ) {
                                                                    const phoneNumber = formatPhoneNumberIntl(values?.phone)
                                                                    let dialCodeWithPlus = phoneNumber.match(/^\+\d+(\s|$)/)[0];
                                                                    let restOfNumber = phoneNumber.slice(dialCodeWithPlus?.length);
                                                                    let mobileNumberWithoutSpaces = restOfNumber.replace(/\s/g, '');
                                                                    let dialCodeWithoutSpaces = dialCodeWithPlus.replace(/\s/g, '');
                                                                    const convertedEndDateTime = moment(values.endDate).endOf('day').format();
                                                                    const appendedZ = moment(convertedEndDateTime).utc().set({ hour: 23, minute: 59, second: 59 }).format();
                                                                    const formattedValues = {
                                                                        tradingName: values.businessName,
                                                                        existsDuring: {
                                                                            endDateTime: appendedZ,
                                                                            // startDateTime: "2023-01-01T00:00:00Z"
                                                                        },
                                                                        domain: values.Domain?.value,
                                                                        state: values.state?.value,
                                                                        city: values.city?.value,
                                                                        country: values.country?.value,
                                                                        postcode: (values.zipCode).toString(),
                                                                        street1: values.Address,
                                                                        street2: values.Address2,
                                                                        // partyCharacteristic: values.attributeList,
                                                                        adminOperator:
                                                                        {
                                                                            userName: values.username,
                                                                            email: values.email,
                                                                            profile: {
                                                                                firstName: values.firstname,
                                                                                lastName: values.lastname
                                                                            },
                                                                            dialCode: dialCodeWithoutSpaces,
                                                                            phone: mobileNumberWithoutSpaces,
                                                                        }
                                                                        ,
                                                                        ...(isEdit ? { status: values.Status?.value } : {})
                                                                    };
                                                                    if (!isEdit) {
                                                                        dispatch(createOperator(formattedValues, setLoading, navigate, logoFile, finalValues, true));
                                                                    } else {
                                                                        dispatch(editOperator(formattedValues, operatorId, setLoading, navigate, profile, update, setUpdate, fileSelected, logoFile, editData, setFileSelected, setEditData,finalValues,configData));
                                                                    }
                                                                    // }
                                                                }} >Confirm</Button>
                                                            </div>
                                                        </div>
                                                    </Row>
                                                </TabPane>
                                            </TabContent>
                                        </div>
                                        <div>
                                        </div>
                                    </Col>
                                </Row>
                            </Container>
                        </Form>
                    )}
                </Formik>
            </div>
        </React.Fragment>
    );
}