export const getStatusLabel = (value) => {
    switch (value) {
        case "Accepted":
            return "Accepted"
        case "Active":
        case "ACTIVE":
            return "Active"
        case "Inactive":
        case "IN-ACTIVE" :
        case "CANCEL":
            return "Inactive"
        case "open":
            return "Billed"
        case "acknowledged":
        case "Acknowledged":
            return "Acknowledged"
        case "In Progress":
        case "inProgress":
            return "In Progress"
        case "failed":
            return "Failed"
        case "completed":
            return "Completed"
        case "partially_paid":
            return "Partially Paid";
        case "unpaid":
        case "pending":
        case "Pending":
            return "Pending";
        case "paid":
        case "close":
            return "Settled";
        case "cancelled":
            return "Cancelled";
        case "Rejected":
            return "Rejected"
        default:
            return "-";
    }
}