import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    terminateSession: [],
    loading: false,
    error: null,
}

const terminateSessionSlice = createSlice({
    name: "terminateSession",
    initialState,
    reducers: {
        fetchTerminateSessionSuccess: (state, action) => {
            state.loading = false,
                state.terminateSession = action.payload;
        },
        fetchTerminateSessionFailure: (state, action) => {
            state.loading = false,
                state.error = action.payload;
        },
    },
})

export const {
    fetchTerminateSessionSuccess,
    fetchTerminateSessionFailure,
} = terminateSessionSlice.actions;

export default terminateSessionSlice.reducer;