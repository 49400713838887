import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    deleteView: [],
    loading: false,
    error: null,
}

const deleteViewSlice = createSlice({
    name: "deleteView",
    initialState,
    reducers: {
        fetchDeleteViewSuccess: (state, action) => {
            state.loading = false
            state.deleteView = action.payload;
        },
        fetchDeleteViewFailure: (state, action) => {
            state.loading = false
            state.error = action.payload;
        },
    },
})

export const {
    fetchDeleteViewSuccess,
    fetchDeleteViewFailure,
} = deleteViewSlice.actions;

export default deleteViewSlice.reducer;